import { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Breadcrums from './util/Breadcrums';
import { useLocation } from "react-router-dom";
import EquipmentTable from './equipmentComponents/EquipmentTable';
import AddEquipment from './modal/AddEquipment';
import Drawer from '@mui/material/Drawer';
import { t } from 'i18next';
import DeleteModalAll from './modal/DeleteModalAll';
import EditEquipment from './modal/EditEquipment';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import AproveDownload from './modal/AproveDownload';
import DownloadIcon from '@mui/icons-material/Download';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import PublishIcon from '@mui/icons-material/Publish';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface EquipmentProps {
  access: any
}

const Equipment: React.FC<EquipmentProps> = ({ access }) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [locationName, setLocationName] = useState('');
  const [status, setStatus] = useState<any[]>([]);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [selectedUser, setSelectedUser] = useState<null>(null);
  const [show, setShow] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [equip, setEquip] = useState([]);
  const [equipOriginal, setEquipOrifinal] = useState([]);
  const location = useLocation();
  const [file, setFile] = useState<File | null>(null);
  const [showAprove, setShowAprove] = useState(false);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<number | null>(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleShowAdd = () => {
    setShowModalAdd(true);
  }

  //open EquipEdit
  const handleShow = (equip: any) => {
    setSelectedUser(equip);
    setShow(true);
  };

  //open EquipDelete
  const handleShowDelete = (equip: any) => {
    setSelectedUser(equip);
    setOpenDel(true);
  };

  //refresh equipment
  const refreshEquip = async () => {
    try {
      const { data: usersEquip } = await axios.get('equipment');
      if (usersEquip) {
        setEquip(usersEquip);
      }
    } catch (error: any) {
      console.log('error users');
    }
  }

  //get all data and check user
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        const { data: usersEquip } = await axios.get('equipment');
        const { data: statusData } = await axios.get('status');
        if (data) {
          setLocationName(location.pathname);
          dispatch(setAuth(true));
        }
        if (usersEquip) {
          setEquip(usersEquip);
          setEquipOrifinal(usersEquip);
          setStatus(statusData);
        }
      } catch (e) {
        dispatch(setAuth(false));
      }
    };
    checkAuth();
  }, [dispatch, location.pathname]);

  //download file (import)
  const importCSV = async () => {
    if (file) {
      try {
        const formData = new FormData();
        formData.append('equipment', file);
        const response = await axios.post('equipment/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.status === 200 || response.status === 201) {
          setFile(null);
          enqueueSnackbar(`${file.name} uploading success`, { variant: 'success' });
          refreshEquip();
        } else {
          setFile(null);
          throw new Error();
        }
      } catch (error) {
        enqueueSnackbar('Error uploading file', { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Please select a file first', { variant: 'warning' });
      setFile(null);
    }
  }

  const downloadSampleXLS = async () => {
    try {
      const response = await axios.post('equipment/csv', null, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sample_equipment.xlsx');
      document.body.appendChild(link);
      link.click();
      setShowAprove(false);
    } catch (error) {
      console.error('Error downloading sample xlsx:', error);
      enqueueSnackbar('Error downloading sample xlsx', { variant: 'error' });
    }
  };

  return (
    <>
      <Breadcrums locationName={locationName} />
      {auth && access.equipment_get > 0 ?
        (<div>
          <div className="bg-[var(--bg-form)] w-full p-[15px] mb-[15px] rounded-[5px]">
            <div className='flex justify-between items-center'>
              <div className='flex justify-between items-center gap-4 w-[55%]'>
                <div className="flex gap-3 justify-start items-center w-full">
                  <button
                    className='w-[180px] border-none text-[16px] rounded-[5px] py-[5px] cursor-pointer bg-[#1976d2] text-white hover:bg-[#176bbe]'
                    onClick={handleShowAdd}>{t('AddEqp')}
                  </button>
                  <input
                    type="text"
                    className='w-[60%] input-timesheet text-[var(--text-color)] bg-[var(--bg-input)] py-[5px] px-[10px] rounded-[5px]'
                    placeholder={t('fillter')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const searchText = e.target.value.toLowerCase();
                      const filteredUsers = equipOriginal.filter((eq: any) =>
                        eq.item_name.toLowerCase().includes(searchText) ||
                        String(eq.inventory_id).includes(searchText) ||
                        eq.manufacturer.toLowerCase().includes(searchText) ||
                        eq.model.toLowerCase().includes(searchText)
                      );
                      setEquip(filteredUsers);
                    }} />
                </div>
              </div>
              <div
                className='flex gap-[10px]'>
                <div className='flex gap-2 items-center'>
                  <p className='text-[14px] font-semibold'>{t('SortBy')}:</p>
                  <select
                    //transfer the value to sortDirection
                    className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
                    value={sortDirection}
                    onChange={(e) => setSortDirection(e.target.value as 'asc' | 'desc')}>
                    <option value="asc">last name A-Z</option>
                    <option value="desc">first name Z-A</option>
                  </select>
                  <select
                  // select status
                  className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
                  value={selectedStatusFilter !== null ? selectedStatusFilter : 'all'}
                  onChange={(e) => setSelectedStatusFilter(e.target.value === 'all' ? null : Number(e.target.value))}>
                  <option value="all">all status</option>
                  {status.map((status) => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
                </select>
                </div>
                <Button
                  color="secondary"
                  startIcon={<DownloadIcon />}
                  variant="outlined"
                  onClick={() => setShowAprove(true)}
                  sx={{
                    padding: '4px 10px'
                  }}>
                  {t('Sample')} XLSX
                </Button>
              </div>
            </div>
          </div>
          <div className='admin-box flex flex-col justify-start p-[15px] rounded-[5px]'>
            <div className='flex justify-between items-center mb-5'>
              <h3 className='text-[18px]'>{t('EqpList')}:</h3>
              <div className='flex gap-[10px] items-center'>
                {file && (
                  <>
                    <FontAwesomeIcon
                      icon={faXmark}
                      className='cursor-pointer hover:text-red-500 hover:rotate-180 hover:transform hover:transition-transform duration-300'
                      onClick={() => setFile(null)} />
                    <div className='flex justify-center mt-[10px] text-[14px]'>
                      <span> {file.name}</span>
                    </div>
                  </>
                )}
                <Button
                  {...(!file && { component: "label" })}
                  {...(file && { color: "success" })}
                  {...(!file && { startIcon: <ExitToAppIcon /> })}
                  {...(file && { startIcon: <PublishIcon /> })}
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  sx={{
                    padding: '3px 10px'
                  }}
                  {...(file && { onClick: importCSV })}>{file ? (t('Import')) : (t('Browse'))} File
                  {!file && (
                    <VisuallyHiddenInput
                      type="file"
                      accept=".xlsx"
                      onChange={(e: any) => {
                        setFile(e.target.files ? e.target.files[0] : null);
                      }}
                    />
                  )}
                </Button>
              </div>
            </div>
            <EquipmentTable
              access={access}
              equip={equip}
              handleShow={handleShow}
              sortDirection={sortDirection}
              handleShowDelete={handleShowDelete}
              status={status}
              refreshEquip={refreshEquip}
              selectedStatusFilter={selectedStatusFilter} />
          </div>
        </div>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </Box>)}
      {openDel && <DeleteModalAll
        open={openDel}
        setOpen={setOpenDel}
        refresh={refreshEquip}
        selectedDateId={selectedUser}
        typeName={'Eqp'}
        typeBack={'equipment'}
      />}
      <Drawer
        anchor="right"
        open={showModalAdd}
        onClose={() => setShowModalAdd(false)}>
        <AddEquipment
          setShowModalAdd={setShowModalAdd}
          refreshEquip={refreshEquip}
          status={status} />
      </Drawer>
      <Drawer
        anchor="right"
        open={show}
        onClose={() => setShow(false)}>
        <EditEquipment
          setShow={setShow}
          status={status}
          refreshEquip={refreshEquip}
          selectedUser={selectedUser} />
      </Drawer>
      {showAprove &&
        <AproveDownload
          showAprove={showAprove}
          setShowAprove={setShowAprove}
          handXls={downloadSampleXLS}
          textField={'AreYouSureEquipment'} />}
    </>
  )
}

export default Equipment
