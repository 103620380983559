import React, { useState, useEffect, SyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../../redux/authSlice';
import { RootState } from '../../redux/store';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

interface TemporaryDrawerProps {
  setShowModalAdd: React.Dispatch<React.SetStateAction<boolean>>;
  refreshUsers: any;
}

export default function UserReg({ setShowModalAdd, refreshUsers }: TemporaryDrawerProps) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [email, setEmail] = useState('');
  const [userInfo, setUserInfo] = useState(0);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        if (data) {
          dispatch(setAuth(true));
          setUserInfo(data.roleId);
        }

      } catch (e) {
        dispatch(setAuth(false));
      }
    };

    checkAuth();
  }, [dispatch, userInfo]);

  const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!firstName || !lastName || !email || !password || !passwordConfirm) {
      enqueueSnackbar('All fields are required', { variant: 'error' });
      return;
    }

    if (!EMAIL_REGEX.test(email)) {
      enqueueSnackbar('Invalid email format', { variant: 'error' });
      return;
    }

    if (password === passwordConfirm) {
      try {
        const response = await axios.post('registerUser', {
          first_name: firstName,
          last_name: lastName,
          second_name: secondName,
          email,
          password,
          password_confirm: passwordConfirm,
        })
        if (response.status === 200 || response.status === 201) {
          setShowModalAdd(false);
          refreshUsers();
          enqueueSnackbar('User registry successfully', { variant: 'success' });
        } else {
          enqueueSnackbar('The user already exists', { variant: 'error' });
        }
      } catch (error: any) {
        console.error('Error:', error);
        enqueueSnackbar('Registration failed. Please try again.', { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Passwords do not match', { variant: 'error' });
    }
  }

  return (
    <Box sx={{
      width: 480,
      height: '200%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div className='my-[10px] mx-[25px] text-[var(--text-color)]'>
          <h1 className='text-[20px] text-right font-bold mb-[20px]'>{t('RegisterUser')}</h1>
          <form className='flex flex-col justify-evenly flex-grow' id='reg-user' onSubmit={submit}>
            <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('RegistrationInformation')}</h2>
            <input placeholder={t('FirstName')} type="text" onChange={e => setFirstName(e.target.value)} />
            <input placeholder={t('LastName')} type="text" onChange={e => setLastName(e.target.value)} />
            <input placeholder={t('SecondName')} type="text" onChange={e => setSecondName(e.target.value)} />
            <input placeholder={t('Email')} type="text" onChange={e => setEmail(e.target.value)} />
            <input placeholder={t('Password')} type="password" onChange={e => setPassword(e.target.value)} />
            <input placeholder={t('ConfirmPassword')} type="password" onChange={e => setPasswordConfirm(e.target.value)} />
            <div className='flex gap-[20px] justify-center items-center mt-[20px]'>
              <button className="bg-[#1976d2] text-white cursor-pointer rounded-[5px] button-border p-[10px] mx-auto w-[70%] hover:bg-[#176bbe]" type='submit'>{t('Register')}</button>
              <button className="bg-[#1976d2] text-white cursor-pointer rounded-[5px] button-border p-[10px] mx-auto w-[70%] hover:bg-[#176bbe]" onClick={(e) => { e.preventDefault(); setShowModalAdd(false) }}>{t('Cancel')}</button>
            </div>
          </form>
        </div>
      </List>
    </Box>
  )
}