export function transformToHierarchy(data: any, parentId = null) {
  const tree: any[] = [];

  data.forEach((item: any) => {
    if (item.parentId === parentId) {
      const children = transformToHierarchy(data, item.id);
      const node = { ...item, id: String(item.id), children };

      if (children.length === 0) {
        delete node.children;
      }

      tree.push(node);
    }
  });

  return tree;
}