import React from 'react';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';

interface TreeViewComponentProps {
  nodes: any[];
  onNodeSelect: (nodeId: number) => void;
  selectedCategoryId: any;
}

interface Node {
  id: number;
  name: string;
  task: {
    name: string,
    id: any
  };
  children?: Node[];
}

const TreeViewEditModal: React.FC<TreeViewComponentProps> = ({ nodes, onNodeSelect, selectedCategoryId }) => {
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleNodeClick = (nodeId: number) => {
    const isNodeExpanded = expanded.includes(nodeId.toString());
    if (isNodeExpanded) {
      setExpanded(prevExpanded => prevExpanded.filter(id => id !== nodeId.toString()));
    } else {
      const newExpanded: string[] = [];
      newExpanded.push(nodeId.toString());
      let parent = findParent(nodes, nodeId);
      while (parent) {
        newExpanded.push(parent.id.toString());
        parent = findParent(nodes, parent.id);
      }
      setExpanded(newExpanded);
    }

    onNodeSelect(nodeId);
  };

  const findParent = (nodes: Node[], nodeId: number): Node | null => {
    for (const node of nodes) {
      if (node.id === nodeId) return null;
      if (node.children && node.children.some(child => child.id === nodeId)) return node;
      if (node.children) {
        const parent = findParent(node.children, nodeId);
        if (parent) return parent;
      }
    }
    return null;
  };

  const renderTree = (nodes: any[]) => (
    nodes
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(node => (
        <TreeItem key={node.id} nodeId={node.id} label={node.name} onClick={() => handleNodeClick(node.id)}
          className='relative'
          icon={node.children && node.children.length > 0 ? <ExpandMoreIcon /> : <ExpandMoreIcon
            className=' opacity-0' />}>
          {node.children && renderTree(node.children)}
        </TreeItem>
      ))
  );

  return (
    <>
      <Box sx={{ mb: 0, ml: 1 }}>
        <button
          className={`w-full text-left p-[2px] hover:bg-[var(--none-button)] active:bg-[var(--none-button)] ${selectedCategoryId[0] === -1 ? 'bg-[var(--none-button)]' : ''
            }`}
          onClick={() => { onNodeSelect(-1) }}
        >none</button>
      </Box>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleToggle}>
        {renderTree(nodes)}
      </TreeView>
    </>
  );
};

export default TreeViewEditModal;