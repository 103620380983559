import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import TablePagination from '@mui/material/TablePagination';
import { t } from 'i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  border: 'var(--border-input)'
}));

const StyledTableCellEmty = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)',
  borderLeft: 'var(--border-input)',
  borderRight: 'var(--border-input)'
}));

const StyledTableCellOneEmty = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  borderTop: 'var(--border-input)',
  borderLeft: 'var(--border-input)',
  borderRight: 'var(--border-input)',
  borderBottom: 'none'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '9px',
  backgroundColor: 'var(--btn-user-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 15
}));

const StyledTableCellAvailability = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  fontSize: '12px',
  border: 'var(--border-input)'
}));

interface ReportsTableProps {
  handleShow: any;
  handleShowDelete: any;
  role: any;
}

const RoleTable: React.FC<ReportsTableProps> = ({ role, handleShow, handleShowDelete }) => {
  const [sortedAccess, setSortedAccess] = React.useState<any[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //sort access by id
  const sortAccessById = (roles: any[]) => {
    return roles.sort((a, b) => {
      if (a.description === -1) return -1;
      if (b.description === -1) return 1;
      return a.id - b.id;
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setSortedAccess(sortAccessById(role));
  }, [role]);

  const StatusView = (value: any, one: any, two: any) => {
    switch (value) {
      case 0:
        return "None";
      case 1:
        return one;
      case 2:
        return two;
      case 3:
        return "All";
      default:
        return "";
    }
  };

  return (
    <>
      {role.length &&
        (<div className='mb-[10px] text-center'>
          <TableContainer
            component={Paper}
            style={{ boxShadow: 'none', backgroundColor: 'inherit', borderBottom: 'var(--border-input)' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCellOneEmty align="center">{t('Roles')}</StyledTableCellOneEmty>
                  <StyledTableCell align="center">{t('Timesheets')}</StyledTableCell>
                  <StyledTableCell colSpan={2} align="center">{t('Reports')}</StyledTableCell>
                  <StyledTableCell colSpan={2} align="center">{t('Approve')}</StyledTableCell>
                  <StyledTableCell align="center">{t('Clients')}</StyledTableCell>
                  <StyledTableCell align="center">{t('Equipment')}</StyledTableCell>
                  <StyledTableCell align="center">{t('Booking')}</StyledTableCell>
                  <StyledTableCell align="center">{t('Expenses')}</StyledTableCell>
                  <StyledTableCell align="center">{t('Orders')}</StyledTableCell>
                  <StyledTableCell align="center">{t('Settimg')}</StyledTableCell>
                  <StyledTableCell colSpan={2} align="center">{t('Toil')}</StyledTableCell>
                  <StyledTableCell colSpan={2} align="center">{t('Users')}</StyledTableCell>
                  <StyledTableCellOneEmty align="center" width='1%'>{t('Edit')}</StyledTableCellOneEmty>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <StyledTableCellEmty></StyledTableCellEmty>
                  <StyledTableCellAvailability align="center">{t('Availability')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Availability')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Edit')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Availability')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Action')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Availability')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Availability')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Availability')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Availability')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Availability')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Availability')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Availability')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Edit')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Availability')}</StyledTableCellAvailability>
                  <StyledTableCellAvailability align="center">{t('Edit')}</StyledTableCellAvailability>
                  <StyledTableCellEmty align="center" width='5%'></StyledTableCellEmty>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAccess
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((role: any, index: any) => (
                    <TableRow
                      key={role.id || index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <StyledTableRow
                        component="th"
                        scope="row"
                        onClick={() => handleShow(role)}
                        className='cursor-pointer'>
                        <div className='flex gap-[5px] items-center justify-start'>
                          <div
                            className="w-[10px] h-[10px] rounded-full"
                            style={{ backgroundColor: `${role.color}` }}
                          ></div>
                          <div>{role.title}</div>
                        </div>
                      </StyledTableRow>
                      <StyledTableRow align="center">{StatusView(role.timesheets_get, 'Self', 'Self+Sub')}</StyledTableRow>
                      <StyledTableRow align="center">{StatusView(role.reports_get, 'Self', 'Self+Sub')}</StyledTableRow>
                      <StyledTableRow align="center">{StatusView(role.reports_do, 'Add', 'Edit')}</StyledTableRow>
                      <StyledTableRow align="center">{StatusView(role.approve_get, 'Team', 'Team+View')}</StyledTableRow>
                      <StyledTableRow align="center">{role.approve_do === 3 ? 'All' : 'Self'}</StyledTableRow>
                      <StyledTableRow align="center">{StatusView(role.clients_get, 'Self', 'Self+Sub')}</StyledTableRow>
                      <StyledTableRow align="center">{StatusView(role.equipment_get, 'Self', 'Self+Sub')}</StyledTableRow>
                      <StyledTableRow align="center">{StatusView(role.booking_get, 'Self', 'Self+Sub')}</StyledTableRow>
                      <StyledTableRow align="center">{StatusView(role.expenses_get, 'Self', 'Self+Sub')}</StyledTableRow>
                      <StyledTableRow align="center">{StatusView(role.orders_get, 'Self', 'Self+Sub')}</StyledTableRow>
                      <StyledTableRow align="center">{role.settings === 1 ? 'See' : 'None'}</StyledTableRow>
                      <StyledTableRow align="center">{StatusView(role.toil_get, 'Self', 'Self+Sub')}</StyledTableRow>
                      <StyledTableRow align="center">{role.toil_do === 1 ? 'All' : 'None'}</StyledTableRow>
                      <StyledTableRow align="center">{StatusView(role.users_get, 'Self', 'Self+Sub')}</StyledTableRow>
                      <StyledTableRow align="center">{StatusView(role.users_do, 'Add', 'Add+Edit')}</StyledTableRow>
                      <StyledTableRow align="right">
                        <div className='flex gap-[8px] justify-end items-center'>
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                              onClick={() => handleShow(role)}
                            />
                          {role.description === -1 ? '' : <FontAwesomeIcon
                            icon={faTrashCan}
                            className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                            onClick={() => handleShowDelete(role.id)}
                          />
                          }
                        </div>
                      </StyledTableRow>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={role.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>)}
    </>
  );
};

export default RoleTable;