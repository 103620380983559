import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { t } from 'i18next';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface DeleteModalProps {
  open: boolean,
  setOpen: any,
  refresh: any,
  selectedDateId: any,
  typeName: any,
  typeBack: any
}

const DeleteModalAll: React.FC<DeleteModalProps> = ({
  open,
  setOpen,
  refresh,
  selectedDateId,
  typeName,
  typeBack }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => setOpen(false);
  const handleDeleteAndClose = async () => {
    try {
      const response = await axios.delete(`/${typeBack}/${selectedDateId}`);
      if (response.status === 200) {
        enqueueSnackbar('delete successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Unable to delete, already in use', { variant: 'error' });
      }
      refresh();
      handleClose();
    } catch (error: any) {
      console.error('Error', error);
      enqueueSnackbar('Error', { variant: 'error' });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2"
              className='text-center'>
              {`${t('AreYouSure')} ${t(typeName)}?`}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}
              className='flex justify-between items-center gap-4'>
              <button
                className='w-full text-[16px] uppercase py-[8px] px-[15px] rounded-[5px] text-white bg-[#1976d2] cursor-pointer border border-black border-opacity-20 hover:bg-[#176bbe]'
                onClick={() => handleDeleteAndClose()}>
                {t('Yes')}</button>
              <button
                className='w-full text-[16px] uppercase py-[8px] px-[15px] rounded-[5px] text-white bg-[#1976d2] cursor-pointer border border-black border-opacity-20 hover:bg-[#176bbe]'
                onClick={() => handleClose()}>
                {t('Cancel')}</button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default DeleteModalAll;