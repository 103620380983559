import React, { useState } from 'react';
import AddRole from '../modal/AddRole';
import RoleTable from './RoleTable';
import Drawer from '@mui/material/Drawer';
import RoleEdit from '../modal/RoleEdit';
import DeleteRole from '../modal/DeleteRole';
import { t } from 'i18next';

interface RoleProps {
  role: any,
  refrefhRole: any,
  openCheckModal: any,
  setCheckOpenModal: any,
  refreshUserHeder: any,
  refreshRoleAccept: any
}

const Role: React.FC<RoleProps> = ({
  role,
  refrefhRole,
  openCheckModal,
  setCheckOpenModal,
  refreshUserHeder,
  refreshRoleAccept }) => {
  const [openAddRole, setOpenAddRole] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [selectedRoleDelete, setSelectedRoleDelete] = useState();
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  //Open AddRole
  const handleOpenAdd = () => {
    setOpenAddRole(true);
    setCheckOpenModal(true);
  };

  //open RoleEdit
  const handleShow = (role: any) => {
    setSelectedRole(role);
    setShow(true);
  };

  //open DeleteRole
  const handleShowDelete = (role: any) => {
    setSelectedRoleDelete(role);
    setShowDelete(true);
  };

  return (
    <>
      <div className='flex justify-between items-center mb-[10px]'>
        <button
          className='border-none rounded-[5px] mb-[10px] text-[16px] py-[5px] px-[20px] cursor-pointer bg-[#1976d2] text-white hover:bg-[#176bbe]'
          onClick={() => handleOpenAdd()}>
          {t('CreateRole')}</button>
      </div>
      <div>
        <RoleTable
          role={role}
          handleShow={handleShow}
          handleShowDelete={handleShowDelete}
        />
      </div>
      {openAddRole &&
        <AddRole
          openAddRole={openAddRole}
          setOpenAddRole={setOpenAddRole}
          refrefhRole={refrefhRole}
          setCheckOpenModal={setCheckOpenModal}
          openCheckModal={openCheckModal} />}
      {showDelete &&
        <DeleteRole
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          selectedRoleDelete={selectedRoleDelete}
          refrefhRole={refrefhRole} />}
      <Drawer
        anchor="right"
        open={show}
        onClose={() => setShow(false)}>
        {selectedRole &&
          <RoleEdit
            refreshUserHeder={refreshUserHeder}
            selectedRole={selectedRole}
            setShow={setShow}
            refrefhRole={refrefhRole}
            refreshRoleAccept={refreshRoleAccept}
          />}
      </Drawer>
    </>
  )
}

export default Role;
