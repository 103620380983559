import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import TreeViewComponent from './util/TreeViewComponent';
import { transformToHierarchy } from './util/tree';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tasks from './adminComponents/Tasks';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Role from './adminComponents/Role';
import Breadcrums from './util/Breadcrums';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';
import Department from './adminComponents/Department';
import ContractType from './adminComponents/ContractType';
import Locations from './adminComponents/Locations';
import CategoryEqu from './adminComponents/CategoryEqu';
import Status from './adminComponents/Status';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import ContractTypeCostumers from './adminComponents/ContractTypeCostumers';
import ContactStatus from './adminComponents/ContactStatus';
import ContractSource from './adminComponents/ContractSource';
import LegalType from './adminComponents/LegalType';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import UploadIcon from '@mui/icons-material/Upload';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: {
    id: number,
    title: string,
    color: string
  },
  company: {
    id: number,
    title: string
  },
  supervisorId: number;
}

interface SettingsProps {
  refreshUserHeder: any,
  refreshRoleAccept: any,
  baseUrl: any,
  is24HourFormat: boolean,
  handleTimeFormatChange: any
}

const Settings: React.FC<SettingsProps> = ({
  refreshUserHeder,
  refreshRoleAccept,
  baseUrl,
  is24HourFormat,
  handleTimeFormatChange }) => {
  const [userInfo, setUserInfo] = useState();
  const [companyName, setCompanyName] = useState();
  const [category, setCategory] = useState<any[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();
  const [openCheckModal, setCheckOpenModal] = useState(false);
  const [tasks, setTasks] = useState<any[]>([]);
  const [dep, setDep] = useState<any[]>([]);
  const [catEqu, setCatEqu] = useState<any[]>([]);
  const [loc, setLoc] = useState<any[]>([]);
  const [status, setStatus] = useState<any[]>([]);
  const [statusClient, setStatusClient] = useState<any[]>([]);
  const [cont, setCont] = useState<any[]>([]);
  const [source, setSource] = useState<any[]>([]);
  const [legal, setLegal] = useState<any[]>([]);
  const [contCost, setContCost] = useState<any[]>([]);
  const [role, setRole] = useState<any[]>([]);
  const transformToHierarchyCallback = useCallback(transformToHierarchy, []);
  const [value, setValue] = useState('category');
  const [subTabValue, setSubTabValue] = useState('department');
  const [equipmentTabValue, setEquipmentTabValue] = useState('category-equipment');
  const [contactTabValue, setContactTabValue] = useState('contract');
  const [locationName, setLocationName] = useState('');
  const location = useLocation();
  const [file, setFile] = useState<File | null>(null);
  const [urlImage, setUrlImage] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleSubTabChange = (event: React.SyntheticEvent, newSubValue: string) => {
    setSubTabValue(newSubValue);
  };

  const handleEquipmentTabChange = (event: React.SyntheticEvent, newEquipmentValue: string) => {
    setEquipmentTabValue(newEquipmentValue);
  };

  const handleContactTabChange = (event: React.SyntheticEvent, newContactValue: string) => {
    setContactTabValue(newContactValue);
  };

  const handleNodeSelect = (nodeId: number) => {
  };

  const refreshAll = async (backName: string, set: any) => {
    try {
      const { data } = await axios.get(`${backName}`);
      set(data);
    } catch (error: any) {
      console.log('error');
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get('user');
        const { data: usersData } = await axios.get('users');
        const { data: categoryData } = await axios.get(`category`);
        const { data: taskData } = await axios.get('task');
        const { data: taskDep } = await axios.get('department');
        const { data: taskCatEqu } = await axios.get('category-equipment');
        const { data: taskLoc } = await axios.get('location');
        const { data: taskCont } = await axios.get('contract-type');
        const { data: roleData } = await axios.get('roles');
        const { data: statusData } = await axios.get('status');
        const { data: taskContCost } = await axios.get('contractType');
        const { data: ClientStatus } = await axios.get('customer-status');
        const { data: source } = await axios.get('contractSource');
        const { data: legal } = await axios.get('legalType');
        const hierarchyData = transformToHierarchyCallback(categoryData);
        setCategory(hierarchyData);
        setUsers(usersData);
        if (data) {
          setUserInfo(data.id);
          setCompanyName(data.company.title);
          if (data.company.logoUrl) {
            setUrlImage(`${baseUrl}${data.company?.logoUrl}`);
          }
        }
        setCheckOpenModal(false);
        setTasks(taskData);
        setDep(taskDep);
        setLoc(taskLoc);
        setCatEqu(taskCatEqu);
        setCont(taskCont);
        setContCost(taskContCost);
        setStatusClient(ClientStatus);
        setLegal(legal);
        setSource(source);
        setStatus(statusData);
        setRole(roleData);
        setLocationName(location.pathname);
        dispatch(setAuth(true));
        const defaultSelectedRoles: { [key: number]: number } = {};
        usersData.forEach((user: any) => {
          if (user.role?.id !== undefined) {
            defaultSelectedRoles[user.id] = user.role.id;
          }
        });
      } catch (e) {
        dispatch(setAuth(false));
      }
    })();
  }, [dispatch, transformToHierarchyCallback, location.pathname, baseUrl, urlImage]);

  const updateTree = useCallback(async () => {
    try {
      const { data: categoryData } = await axios.get('category');
      const hierarchyData = transformToHierarchyCallback(categoryData);
      setCategory(hierarchyData);
    } catch (error) {
      console.error('Error updating category tree:', error);
    }
  }, [transformToHierarchyCallback]);

  const handleSubmit = async () => {
    if (file) {
      const fileType = file.type;
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      const maxSize = 1 * 1024 * 1024;
      if (!allowedTypes.includes(fileType)) {
        enqueueSnackbar('Error: File must be in PNG, JPG, or JPEG format', { variant: 'error' });
        return;
      }
      if (file.size > maxSize) {
        enqueueSnackbar('Error: File size must be less than 1MB', { variant: 'error' });
        return;
      }
      try {
        const formData = new FormData();
        formData.append('logo', file);
        await axios.post('company/upload/companyLogo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const { data } = await axios.get(`user`);
        if (data) {
          setUrlImage(`${baseUrl}${data.company.logoUrl}`);
          setFile(null);
        }
        refreshUserHeder();
        enqueueSnackbar('Uploading success', { variant: 'success' });
      } catch (error) {
        console.error('Error uploading file:', error);
        enqueueSnackbar('Error uploading file', { variant: 'error' });
      }
    }
  };

  const handleDeleteLogo = async (e: any) => {
    await axios.delete(`company/deleteLogo`);
    setUrlImage('');
    setFile(null);
    refreshUserHeder();
  }

  return (
    <>
      <Breadcrums locationName={locationName} />
      {auth && userInfo ? (
        <>
          <div className="mb-[15px]">
            <div className="admin-box-title bg-[var(--bg-form)] flex flex-col justify-start p-[15px] rounded-[5px]">
              <h3 className='text-[20px] mb-[10px]'>{t('CompanyInfo')}:</h3>
              <div className='flex flex-col w-full'>
                <div className='flex'>
                  {urlImage &&
                    <div
                      className='w-[200px] h-[40px] mb-[10px] ml-[10px] relative'>
                      <img
                        className="w-full h-full object-content"
                        src={urlImage} alt="logo" />
                      <div
                        className='absolute top-0 left-0 timesheet-edit-input h-[40px] w-[40px]'></div>
                      <FontAwesomeIcon
                        icon={faXmark}
                        className='absolute top-[-5px] right-[-10px] cursor-pointer hover:text-red-500 hover:rotate-180 hover:transform hover:transition-transform duration-300'
                        onClick={handleDeleteLogo} />
                    </div>}
                </div>
                <div
                  className='flex my-[10px] gap-[10px] items-center mx-[10px]'>
                  <Button
                    {...(!file && { component: "label" })}
                    {...(file && { color: "success" })}
                    {...(file && { startIcon: <UploadIcon /> })}
                    {...(!file && { startIcon: <ExitToAppIcon /> })}
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    sx={{
                      padding: '3px 10px'
                    }}
                    {...(file && { onClick: handleSubmit })}>{file ? (t('Upload logo')) : (t('Browse Logo'))}
                    {!file && (
                      <VisuallyHiddenInput
                        type="file"
                        accept=".png, .jpeg, .jpg"
                        onChange={(e: any) => {
                          setFile(e.target.files ? e.target.files[0] : null);
                        }}
                      />
                    )}
                  </Button>
                  {file && (
                    <>
                      <div className='flex justify-center mt-[10px] text-[14px]'>
                        <span> {file.name}</span>
                      </div>
                      <FontAwesomeIcon
                        icon={faXmark}
                        className='cursor-pointer hover:text-red-500 hover:rotate-180 hover:transform hover:transition-transform duration-300'
                        onClick={() => setFile(null)} />
                    </>)}
                </div>
              </div>
              <p
                className='text-[10px] opacity-40 ml-[10px]'>image 200x40, small 40x40</p>
              <div key={companyName} className='text-[16px] ml-[10px] mb-[10px]'>{t('CompanyName')}: <span className=' font-semibold'>"{companyName}"</span></div>
              <div className='flex ml-[10px] mb-[10px] gap-[5px] justify-start items-center'>
                <FormControlLabel control={
                  <Checkbox
                    onChange={handleTimeFormatChange}
                    checked={!is24HourFormat} />
                }
                  label="Format time AM/PM" />
              </div>
              {users?.length && users
                .filter((user) => user.id === userInfo)
                .map((user: User) => (
                  <div key={user.id} className='bg-[var(--btn-user)] mb-[5px] p-[10px] flex justify-between text-[14px] items-center rounded-[10px]'>
                    <div className="flex gap-[10px] items-center">
                      <div className="flex justify-center items-center text-white w-[40px] h-[40px] text-[18px] rounded-[50%] uppercase" style={{ backgroundColor: `${user.role?.color}` }}>
                        {user.first_name[0]}{user.last_name[0]}</div>
                      <div className='flex gap-[5px] item-center flex-wrap'>
                        <div className="user-box-name">{user.first_name}</div>
                        <div className="user-box last">{user.last_name}</div>
                      </div>
                    </div>
                    <div className='flex justify-between items-center'>
                      <div className='flex gap-[10px] item-center flex-wrap justify-between'>
                        <div className="text-center max-w-[150px] px-[10px] rounded-[5px] bg-[var(--bg-form)] text-[var(--text-color)] user-box-view-all">{users.find(userid => userid.id === user.supervisorId)?.first_name || 'none'}</div>
                        <div className="text-center max-w-[150px] px-[10px] rounded-[5px] bg-[var(--bg-form)] text-[var(--text-color)] user-box-view-all">{user.role.title}</div>
                        <div className="text-center max-w-[300px] px-[10px] rounded-[5px] bg-[var(--bg-form)] text-[var(--text-color)] user-box-view-all">{user.email}</div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="admin-timesheets-tree flex flex-col justify-start p-[15px] rounded-[5px] mb-[15px]">
            <h3 className='text-[20px] mb-[10px]'>{t('EditPanel')}:</h3>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{
                  borderBottom: 1,
                  borderColor: "rgba(var(--shodow), 0.05)"
                }}>
                  <TabList onChange={handleChange} >
                    <Tab label="Category" value='category' sx={{ color: 'var(--text-color)', fontSize: 16 }} />
                    <Tab label="Tasks" value='task' sx={{ color: 'var(--text-color)', fontSize: 16 }} />
                    <Tab label="Role" value='role' sx={{ color: 'var(--text-color)', fontSize: 16 }} />
                    <Tab label="Users" value='users' sx={{ color: 'var(--text-color)', fontSize: 16 }} />
                    <Tab label="Equipment" value='equipment' sx={{ color: 'var(--text-color)', fontSize: 16 }} />
                    <Tab label="Client-Company" value='client' sx={{ color: 'var(--text-color)', fontSize: 16 }} />
                  </TabList>
                </Box>
                <TabPanel value='category'>
                  <TreeViewComponent
                    nodes={category}
                    onNodeSelect={handleNodeSelect}
                    updateTree={updateTree}
                    openCheckModal={openCheckModal}
                    selectedCategoryId={''} />
                </TabPanel>
                <TabPanel value='task'>
                  <Tasks
                    tasks={tasks}
                    refrefhTasks={() => refreshAll('task', setTasks)}
                    setCheckOpenModal={setCheckOpenModal} />
                </TabPanel>
                <TabPanel value='role'>
                  <Role
                    role={role}
                    refrefhRole={() => refreshAll('roles', setRole)}
                    refreshRoleAccept={refreshRoleAccept}
                    refreshUserHeder={refreshUserHeder}
                    openCheckModal={openCheckModal}
                    setCheckOpenModal={setCheckOpenModal} />
                </TabPanel>
                <TabPanel value='users'>
                  <TabContext value={subTabValue}>
                    <Box sx={{
                      borderBottom: 1,
                      borderColor: "rgba(var(--shodow), 0.05)",
                    }}>
                      <TabList onChange={handleSubTabChange}>
                        <Tab label="Department" value='department' sx={{ color: 'var(--text-color)' }} />
                        <Tab label="Contract-Type" value='contract' sx={{ color: 'var(--text-color)' }} />
                      </TabList>
                    </Box>
                    <TabPanel value='department'>
                      <Department
                        dep={dep}
                        refrefhDep={() => refreshAll('department', setDep)}
                        setCheckOpenModal={setCheckOpenModal} />
                    </TabPanel>
                    <TabPanel value='contract'>
                      <ContractType
                        cont={cont}
                        refrefhCont={() => refreshAll('contract-type', setCont)}
                        setCheckOpenModal={setCheckOpenModal} />
                    </TabPanel>
                  </TabContext>
                </TabPanel>
                <TabPanel value='equipment'>
                  <TabContext value={equipmentTabValue}>
                    <Box sx={{
                      borderBottom: 1,
                      borderColor: "rgba(var(--shodow), 0.05)"
                    }}>
                      <TabList onChange={handleEquipmentTabChange}>
                        <Tab label="Category-Equipment" value='category-equipment' sx={{ color: 'var(--text-color)' }} />
                        <Tab label="Locations" value='locations' sx={{ color: 'var(--text-color)' }} />
                        <Tab label="Status" value='status' sx={{ color: 'var(--text-color)' }} />
                      </TabList>
                    </Box>
                    <TabPanel value='category-equipment'>
                      <CategoryEqu
                        catEqu={catEqu}
                        refrefhCatQu={() => refreshAll('category-equipment', setCatEqu)}
                        setCheckOpenModal={setCheckOpenModal} />
                    </TabPanel>
                    <TabPanel value='locations'>
                      <Locations
                        loc={loc}
                        refrefhLoc={() => refreshAll('location', setLoc)}
                        setCheckOpenModal={setCheckOpenModal} />
                    </TabPanel>
                    <TabPanel value='status'>
                      <Status
                        status={status}
                        refrefhStatus={() => refreshAll('status', setStatus)}
                        setCheckOpenModal={setCheckOpenModal} />
                    </TabPanel>
                  </TabContext>
                </TabPanel>
                <TabPanel value='client'>
                  <TabContext value={contactTabValue}>
                    <Box sx={{
                      borderBottom: 1,
                      borderColor: "rgba(var(--shodow), 0.05)"
                    }}>
                      <TabList onChange={handleContactTabChange}>
                        <Tab label="Contract-Type" value='contract' sx={{ color: 'var(--text-color)' }} />
                        <Tab label="Status" value='status' sx={{ color: 'var(--text-color)' }} />
                        <Tab label="Source" value='source' sx={{ color: 'var(--text-color)' }} />
                        <Tab label="Legal-Type" value='legal' sx={{ color: 'var(--text-color)' }} />
                      </TabList>
                    </Box>
                    <TabPanel value='contract'>
                      <ContractTypeCostumers
                        cont={contCost}
                        refrefhContCost={() => refreshAll('contractType', setContCost)}
                        setCheckOpenModal={setCheckOpenModal} />
                    </TabPanel>
                    <TabPanel value='status'>
                      <ContactStatus
                        status={statusClient}
                        refrefhClientStatus={() => refreshAll('customer-status', setStatusClient)}
                        setCheckOpenModal={setCheckOpenModal} />
                    </TabPanel>
                    <TabPanel value='source'>
                      <ContractSource
                        source={source}
                        refreshSource={() => refreshAll('contractSource', setSource)}
                        setCheckOpenModal={setCheckOpenModal} />
                    </TabPanel>
                    <TabPanel value='legal'>
                      <LegalType
                        legal={legal}
                        refreshLegal={() => refreshAll('legalType', setLegal)}
                        setCheckOpenModal={setCheckOpenModal} />
                    </TabPanel>
                  </TabContext>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default Settings;