import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import axios from 'axios';
import enGB from 'date-fns/locale/en-GB';
import { registerLocale } from "react-datepicker";
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import DateRangePickerBookin from '../bookingComponents/DateRangePickerBookin';
import BookingTable from '../bookingComponents/BookingTable';

registerLocale('en-GB', enGB);

interface Booking {
  id: number;
  equipmentId: number;
  userId: number;
  clientId: number | null;
  startTime: string;
  endTime: string;
  user: {
    id: number;
    first_name: string;
    last_name: string;
  };
  client: any | null
}

interface TemporaryDrawerProps {
  setShow: any,
  selectedBooking: any,
  refreshBooking: any,
  range: any,
}

export default function AddBooking({ setShow, selectedBooking, refreshBooking, range }: TemporaryDrawerProps) {
  const [users, setUsers] = useState([]);
  const [bookedRanges, setBookedRanges] = useState();
  const [bookingRow, setBookingRow] = useState<Booking[]>([]);
  const [userOrClient, setUserOrClient] = useState(false);
  const [user, setUser] = useState<any | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const handleClose = () => setShow(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectChangeUser = (event: any) => {
    setUser(Number(event.target.value));
  };

  useEffect(() => {
    (async () => {
      if (selectedBooking) {
        try {
          const { data: usersData } = await axios.get('users');
          setUsers(usersData);
          if (range && range.booking) {
            const ranges = range.booking.map((b: any) => ({
              start: new Date(b.startTime),
              end: new Date(b.endTime)
            }));
            setBookedRanges(ranges);
            if (range) {
              setBookingRow(range.booking);
            }
          }
        } catch (e) {
        }
      }
    })();
  }, [selectedBooking, range]);

  const handleEquipment = async () => {
    if (!user) {
      enqueueSnackbar('Choose user or client', { variant: 'error' });
      return
    }
    if (!startDate || !endDate) {
      enqueueSnackbar('Both start date and end date must be selected', { variant: 'error' });
      return;
    }
    if (startDate > endDate) {
      enqueueSnackbar('Start date should be before end date', { variant: 'error' });
      return;
    }
    try {
      const response = await axios.post('booking/add', {
        equipmentId: selectedBooking,
        startTime: startDate,
        endTime: endDate,
        userId: user
      });
      if (response.status === 200 || response.status === 201) {
        enqueueSnackbar('Successfully', { variant: 'success' });
        handleClose();
        refreshBooking();
      } else {
        enqueueSnackbar("the time intervals are overlapping", { variant: 'error' });
        return;
      }
    } catch (e) {
      enqueueSnackbar('Error creating', { variant: 'error' });
    }
  };

  return (
    <Box sx={{
      width: 450,
      height: '200%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div className='my-[5px] mx-[25px] text-[var(--text-color)]' >
          <h1 className='text-[20px] text-right font-bold mb-[10px]'>{t('Add Booking')}</h1>
          <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('Date booking')}</h2>
          <div className='mb-[10px]'>
            <p className='text-[12px]'>{t('Date')}:</p>
            <div className='flex justify-normal items-center'>
              <DateRangePickerBookin
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                bookedRanges={bookedRanges} />
            </div>
          </div>
          <hr className='hr mt-1' />
          <div className='mb-[20px]'>
            <div className='flex justify-start items-center gap-2 my-3 ml-2'>
              <input
                type="checkbox"
                checked={userOrClient}
                onChange={() => setUserOrClient(!userOrClient)} />
              <label className='text-[16px]'>{t('Client')}</label>
            </div>
            {!userOrClient ? (
              <>
                <label className='text-[10px] ml-[5px]'>{t('Select User')}:</label>
                <select
                  className='w-full text-[16px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                  onChange={handleSelectChangeUser}
                  value={user ? user : 'Select User'}>
                  <option value="Select User" disabled>Select User</option>
                  {users.map((name: any) => (
                    <option key={name.id} value={name.id}>
                      {name.first_name} {name.last_name}
                    </option>))}
                </select>
              </>
            ) : (
              <>
                <label className='text-[10px] ml-[5px]'>{t('Select Client')}:  no client </label>
              </>
            )}
          </div>
          <div>
            <h2 className='text-[14px] mt-[5px] mb-[15px] font-semibold text-right'>{t('Date booking table')}</h2>
            {bookingRow && bookingRow.length ? (
              <BookingTable
                bookingRow={bookingRow}
                refreshBooking={refreshBooking}
                setBookingRow={setBookingRow} />
            ) : (
              <div className='text-center opacity-40 mb-[20px]'>no time booking</div>
            )}

          </div>
          <div
            className='flex justify-between items-center gap-4'>
            <button
              className='w-full text-[16px] uppercase py-[8px] px-[15px] rounded-[5px] text-white bg-[#1976d2] cursor-pointer border border-black border-opacity-20 hover:bg-[#176bbe]'
              onClick={() => handleEquipment()}>
              {t('Add')}</button>
            <button
              className='w-full text-[16px] uppercase py-[8px] px-[15px] rounded-[5px] text-white bg-[#1976d2] cursor-pointer border border-black border-opacity-20 hover:bg-[#176bbe]'
              onClick={() => handleClose()}>
              {t('Cancel')}</button>
          </div>
        </div>
      </List>
    </Box>
  )
}