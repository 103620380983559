import React, { SyntheticEvent, useState, useEffect } from 'react'
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { setAuth } from '../../redux/authSlice';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      const {data} = await axios.post('login', {
      email,
      password
    }, {withCredentials: true});
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
    setRedirect(true)
    } catch (e) {
      console.error('Error!');
      setError('Authentication failed. Please check your email and password.');
    }
  }

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        if (data) {
          dispatch(setAuth(true));
        }
      } catch (e) {
        dispatch(setAuth(false));
      }
    };
    checkAuth();
  }, [dispatch]);

  if (redirect) {
    return <Navigate to={'/'} />
  }


  return (
    (!auth ? (
    <div className="max-w-[480px] m-auto flex flex-col justify-start p-[40px] rounded-[5px] form-box">
      {error && <div className='relative px-5 py-3 mb-4 text-center rounded text-red-900 bg-red-200 border-gray-200'>{error}</div>}
      <h1 className='mt-[20px] text-[26px]'>Sign In</h1>
      <form className="flex flex-col justify-evenly flex-grow pb-4" onSubmit={submit}>
        <input placeholder='Email' type="text" id="email" onChange={e => setEmail(e.target.value)} />
        <input placeholder='Password' type="password" id="password" onChange={e => setPassword(e.target.value)} />
        <Link to='/forgot' className='text-[18px] my-[4px] text-[#1976d2]'>Forgot password?</Link>
        <button className="bg-[#1976d2] text-white cursor-pointer rounded-[5px] button-border p-[10px] mx-auto mt-[20px] w-[70%] hover:bg-[#176bbe]" type='submit'>Sign In</button>
      </form>
      <p className='text-[18px] text-center'>Need an Account? <span className="inline-block"> <Link className="text-[#1976d2] opacity-0" to="/register">Sign Up</Link></span></p>
    </div>
  ) : <Navigate to='/'/>
    ))
}

export default Login