import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import TablePagination from '@mui/material/TablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import TableSortLabel from '@mui/material/TableSortLabel';
import { t } from 'i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px',
  backgroundColor: 'var(--btn-user-form-notactive)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

const StyledTableSort = styled(TableSortLabel)(({ theme }) => ({
  fontSize: 14,
}));

interface ReportsTableProps {
  users: any;
  userAll: any;
  handleShow: any;
  handleShowDelete: any;
  access: any;
  baseUrl: string;
}

const UsersTableNotActive: React.FC<ReportsTableProps> = ({
  users, handleShow, handleShowDelete, access, baseUrl, userAll }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [orderBy, setOrderBy] = useState('first_name');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
  }, [users])

  return (
    <>
      {Array.isArray(users) && users.length > 0 ? (
        <div className='mb-[10px] text-center'>
          <TableContainer
            component={Paper}
            style={{ boxShadow: 'none', backgroundColor: 'inherit', borderBottom: 'var(--border-input)' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <StyledTableSort
                      active={orderBy === 'first_name'}
                      direction={orderBy === 'first_name' ? order : 'asc'}
                      onClick={() => handleRequestSort('first_name')}
                    >
                      {t('Name')}
                    </StyledTableSort>
                  </StyledTableCell>
                  <StyledTableCell>{t('Email')}</StyledTableCell>
                  <StyledTableCell>{t('Role')}</StyledTableCell>
                  <StyledTableCell>{t('Supervisor')}</StyledTableCell>
                  <StyledTableCell align="right" width='8%'>{t('Edit')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  ?.sort((a: any, b: any) => {
                    const compareValueA = order === 'asc' ? a.first_name : b.first_name;
                    const compareValueB = order === 'asc' ? b.first_name : a.first_name;
                    return compareValueA.localeCompare(compareValueB);
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user: any, index: any) => (
                    <TableRow
                      key={user.dateId || index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <StyledTableRow
                        component="th"
                        scope="row">
                        {<div className="flex gap-[10px] items-center cursor-pointer"
                          onClick={() => handleShow(user)}>
                          <div className='flex gap-[5px] item-center flex-wrap'>
                            <div className="user-box-name">{user.first_name}</div>
                            <div className="user-box last">{user.last_name}</div>
                            <div className="user-box second">{user.second_name}</div>
                          </div>
                        </div>}
                      </StyledTableRow>
                      <StyledTableRow>{user.email}</StyledTableRow>
                      <StyledTableRow>{user.role?.title}</StyledTableRow>
                      <StyledTableRow>{((userAll.find((userid: any) => userid.id === user.supervisorId)?.first_name || 'none') + ' ' + (userAll.find((userid: any) => userid.id === user.supervisorId)?.last_name || ''))}</StyledTableRow>
                      {access ? (<StyledTableRow align="right">
                        <div className='flex gap-[7px] justify-end items-center'>
                          {access.users_do >= 2 ? (
                            <div className='flex gap-[7px] justify-end items-center'>
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                                onClick={() => handleShow(user)} /></div>) : ''}
                          {access.users_do > 2 ? (
                            <div className='flex gap-[7px] justify-end items-center'>
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                                onClick={() => handleShowDelete(user)}
                                 /></div>) : ''}
                        </div>
                      </StyledTableRow>) : ''}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      ) : (
        <div className='text-center text-[var(--text-color)]'>{t('No users available')}</div>
      )}
    </>
  );
};

export default UsersTableNotActive;