import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import axios from 'axios';
import enGB from 'date-fns/locale/en-GB';
import DatePicker, { registerLocale } from "react-datepicker";
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

registerLocale('en-GB', enGB);

interface TemporaryDrawerProps {
  setShow: any,
  refreshEquip: any,
  selectedUser: any,
  status: any
}

export default function EditEquipment({ setShow, refreshEquip, selectedUser, status }: TemporaryDrawerProps) {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState<any | null>(null);
  const [userPat, setUserPat] = useState<any | null>(null);
  const handleClose = () => setShow(false);
  const [warDate, setWarDate] = useState<Date | null>(null);
  const [purchDate, setPurchDate] = useState<Date | null>(null);
  const [patTTDate, setPatTTDate] = useState<Date | null>(null);
  const [patTDDate, setPatTDDate] = useState<Date | null>(null);
  const [location, setLocation] = useState<any | null>(null);
  const [locationAll, setLocationAll] = useState<any | null>(null);
  const [categoryEQ, setCategoryEQ] = useState<any | null>(null);
  const [categoryEQAll, setCategoryEQAll] = useState<any | null>(null);
  const [name, setName] = useState('');
  const [serial, setSerial] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [model, setModel] = useState('');
  const [inventoryID, setInventoryID] = useState<number | null>(null);
  const [cost, setCost] = useState<number | null>(null);
  const [statusQ, setStatusQ] = useState<number | null>(null);
  const [notes, setNotes] = useState('');
  const [rental, setRental] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleDateChange = (date: Date, set: React.Dispatch<React.SetStateAction<Date | null>>, type?: string) => {
    set(date);
    if (type === 'patTDDate') {
      if (!date) {
        return
      }
      const newWarDate = new Date(date);
      newWarDate.setFullYear(newWarDate.getFullYear() + 1);
      setPatTTDate(newWarDate);
    }
  };

  const handleSelectChangeLocal = (event: any) => {
    setLocation(Number(event.target.value));
  };

  const handleSelectChangeStatus = (event: any) => {
    setStatusQ(Number(event.target.value));
  };

  const handleSelectChangeCategory = (event: any) => {
    setCategoryEQ(Number(event.target.value));
  };

  const handleSelectChangeUser = (event: any) => {
    setUser(Number(event.target.value));
  };

  const handleSelectChangePatUser = (event: any) => {
    setUserPat(Number(event.target.value));
  };

  const handleSelectChangeNumbers = (event: any) => {
    setInventoryID(Number(event.target.value));
  };

  const handleSelectChangeCost = (event: any) => {
    setCost(Number(event.target.value));
  };

  const handleSelectChange = (event: any, set: React.Dispatch<React.SetStateAction<string>>) => {
    set(event.target.value);
  };

  const handleRentalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRental(event.target.checked);
  };

  useEffect(() => {
    (async () => {
      if (selectedUser) {
        try {
          const { data } = await axios.get(`equipment/${selectedUser.id}`);
          const { data: usersData } = await axios.get('users');
          const { data: Loc } = await axios.get(`location`);
          const { data: Cat } = await axios.get(`category-equipment`);
          if (Loc) {
            setLocationAll(Loc);
          }
          if (Cat) {
            setCategoryEQAll(Cat);
          }
          if (usersData) {
            setUsers(usersData);
          }
          if (data) {
            setLocation(data.locationId);
            setCategoryEQ(data.categoryEquipmentId);
            setName(data.item_name);
            setSerial(data.serial_number);
            setManufacturer(data.manufacturer);
            setModel(data.model);
            setInventoryID(data.inventory_id);
            setCost(data.cost);
            setNotes(data.notes);
            setUser(data.responsibleUserId);
            setUserPat(data.patUserId);
            setRental(data.rental_opportunity);
            setWarDate(data.warranty);
            setPurchDate(data.purchase_date);
            setPatTTDate(data.pat_due);
            setPatTDDate(data.pat_date);
            setStatusQ(data.statusId);
          }

        } catch (e) {
        }
      }
    })();
  }, [selectedUser]);

  const handleEquipment = async () => {
    if (name.trim() === '') {
      enqueueSnackbar('Name is empty', { variant: 'error' });
      return
    }

    try {
      await axios.put(`equipment/${selectedUser.id}`,
        {
          item_name: name,
          inventory_id: inventoryID,
          manufacturer,
          model,
          serial_number: serial,
          cost,
          notes,
          locationId: location,
          categoryEquipmentId: categoryEQ,
          responsibleUserId: user,
          patUserId: userPat,
          purchase_date: purchDate,
          warranty: warDate,
          pat_date: patTDDate,
          pat_due: patTTDate,
          rental_opportunity: rental,
          statusId: statusQ
        });
      handleClose();
      refreshEquip();
      enqueueSnackbar('Successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error creating', { variant: 'error' });
      console.error('Error creating:', error);
    }
  };

  return (
    <Box sx={{
      width: 480,
      height: '200%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div className='my-[5px] mx-[25px] text-[var(--text-color)]' >
          <div className='flex justify-between items-center'>
            <div className='my-[5px] flex gap-[5px] justify-start items-center'>
              <label className='text-[14px] ml-[5px]'>{t('Status')}: </label>
              <select
                className='w-full edit-select py-[2px] px-[5px] rounded-[5px] edit-input text-[14px]'
                onChange={handleSelectChangeStatus}
                value={statusQ || 'none'}>
                <option value="none">none</option>
                {status?.map((status: any) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>))}
              </select>
            </div>
            <h1 className='text-[20px] text-right font-bold'>{t('EditEqp')}</h1>
          </div>
          <div className='flex flex-col justify-evenly flex-grow mb-[5px]' >
            <h2 className='text-[14px] my-[5px] font-semibold text-right mb-2'>{t('ItemInfo')}</h2>
            <div>
              <div className='mb-2'>
                <input
                  className='w-full text-[16px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                  type="text"
                  placeholder={t('Name')}
                  value={name ? name : ''}
                  onChange={(e) => handleSelectChange(e, setName)} />
              </div>
              <div className='mb-2'>
                <input
                  className='w-full text-[16px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                  type="text"
                  placeholder={t('SerialNumber')}
                  value={serial ? serial : ''}
                  onChange={(e) => handleSelectChange(e, setSerial)} />
              </div>
              <div className='mb-2'>
                <input
                  className='w-full text-[16px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                  type="text"
                  placeholder={t('Manufacturer')}
                  value={manufacturer ? manufacturer : ''}
                  onChange={(e) => handleSelectChange(e, setManufacturer)} />
              </div>
              <div className='mb-2'>
                <input
                  className='w-full text-[16px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                  type="text"
                  placeholder={t('Model')}
                  value={model ? model : ''}
                  onChange={(e) => handleSelectChange(e, setModel)} />
              </div>
              <div className='flex gap-[7px] w-full mb-2'>
                <div className='w-full'>
                  <input
                    className='w-full text-[16px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                    type="number"
                    placeholder={t('InventoryID')}
                    value={inventoryID ? inventoryID : ''}
                    onChange={handleSelectChangeNumbers} />
                </div>
                <div className='w-full'>
                  <input
                    className='w-full text-[16px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                    type="text"
                    placeholder={t('Cost')}
                    value={cost ? cost : ''}
                    onChange={handleSelectChangeCost} />
                </div>
              </div>
              <div className='mb-[10px]'>
                <label className='text-[10px] ml-[5px]'>{t('ResponsibleForItem')}:</label>
                <select
                  className='w-full text-[16px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                  onChange={handleSelectChangeUser}
                  value={user ? user : 'none'}>
                  <option value="none">none</option>
                  {users.map((name: any) => (
                    <option key={name.id} value={name.id}>
                      {name.first_name} {name.last_name}
                    </option>))}
                </select>
              </div>
              <div className='mb-2'>
                <input
                  className='w-full text-[16px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                  type="text"
                  placeholder={t('Notes')}
                  value={notes ? notes : ''}
                  onChange={(e) => handleSelectChange(e, setNotes)} />
              </div>
              <div className='flex gap-[7px] w-full'>
                <div className='flex flex-col w-full'>
                  <label className='text-[10px] ml-[5px]'>{t('PurchaseDate')}</label>
                  <DatePicker
                    selected={purchDate ? new Date(purchDate) : null}
                    onChange={(date: any) => handleDateChange(date, setPurchDate)}
                    className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                    dateFormat="dd/MM/yyyy"
                    popperPlacement="bottom-start"
                    locale="en-GB"
                  />
                </div>
                <div className='flex flex-col w-full'>
                  <label className='text-[10px] ml-[5px]'>{t('WarrantyDue')}</label>
                  <DatePicker
                    selected={warDate ? new Date(warDate) : null}
                    onChange={(date: any) => handleDateChange(date, setWarDate)}
                    className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                    dateFormat="dd/MM/yyyy"
                    popperPlacement="bottom-start"
                    locale="en-GB"
                  />
                </div>
              </div>
              <div className='w-full my-[5px]'>
                <label className='text-[10px] ml-[5px]'>{t('Location')}</label>
                <select
                  className='w-full edit-select py-[5px] px-[8px] rounded-[5px] edit-input'
                  onChange={handleSelectChangeLocal}
                  value={location || ''}>
                  {locationAll?.map((location: any) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>))}
                </select>
              </div>
              <div className='w-full'>
                <label className='text-[10px] ml-[5px]'>{t('Category')}</label>
                <select
                  className='w-full edit-select py-[5px] px-[8px] rounded-[5px] edit-input'
                  onChange={handleSelectChangeCategory}
                  value={categoryEQ || ''}>
                  {categoryEQAll?.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>))}
                </select>
              </div>
              <div className='flex justify-start items-center gap-2 my-3 ml-2'>
                <input
                  type="checkbox"
                  checked={rental || false}
                  onChange={handleRentalChange} />
                <label className='text-[16px]'>{t('RentalOpportunity')}</label>
              </div>
            </div>
            <div className='flex flex-col justify-evenly flex-grow mb-[20px]' >
              <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('PATInfo')}</h2>
              <div className='flex gap-[7px] w-full'>
                <div className='flex flex-col w-full'>
                  <label className='text-[10px] ml-[5px]'>{t('PATTestDate')}</label>
                  <DatePicker
                    selected={patTDDate ? new Date(patTDDate) : null}
                    onChange={(date: any) => handleDateChange(date, setPatTDDate, 'patTDDate')}
                    className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                    dateFormat="dd/MM/yyyy"
                    popperPlacement="bottom-start"
                    locale="en-GB"
                  />
                </div>
                <div className='flex flex-col w-full'>
                  <label className='text-[10px] ml-[5px]'>{t('PATTestDue')}</label>
                  <DatePicker
                    selected={patTTDate ? new Date(patTTDate) : null}
                    onChange={(date: any) => handleDateChange(date, setPatTTDate)}
                    className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                    dateFormat="dd/MM/yyyy"
                    popperPlacement="bottom-start"
                    locale="en-GB"
                  />
                </div>
              </div>
              <div className='my-[3px]'>
                <label className='text-[10px] ml-[5px]'>{t('ResponsibleForPAT')}:</label>
                <select
                  className='w-full text-[16px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                  onChange={handleSelectChangePatUser}
                  value={userPat ? userPat : 'none'}>
                  <option value="none">none</option>
                  {users.map((name: any) => (
                    <option key={name.id} value={name.id}>
                      {name.first_name} {name.last_name}
                    </option>))}
                </select>
              </div>
            </div>
          </div>
          <div
            className='flex justify-between items-center gap-4'>
            <button
              className='w-full text-[16px] uppercase py-[8px] px-[15px] rounded-[5px] text-white bg-[#1976d2] cursor-pointer border border-black border-opacity-20 hover:bg-[#176bbe]'
              onClick={() => handleEquipment()}>
              {t('Change')}</button>
            <button
              className='w-full text-[16px] uppercase py-[8px] px-[15px] rounded-[5px] text-white bg-[#1976d2] cursor-pointer border border-black border-opacity-20 hover:bg-[#176bbe]'
              onClick={() => handleClose()}>
              {t('Cancel')}</button>
          </div>
        </div>
      </List>
    </Box>
  )
}