import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import TablePagination from '@mui/material/TablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import moment from 'moment';
import 'moment/locale/uk';
import DeleteModalBookTable from '../modal/DeleteModalBookTable';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '5px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '5px',
  backgroundColor: 'var(--btn-user-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 13
}));

interface ReportsTableProps {
  bookingRow: any;
  refreshBooking: any;
  setBookingRow: any
}

const BookingTable: React.FC<ReportsTableProps> = ({
  bookingRow, refreshBooking, setBookingRow }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState<number | null>(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = (dateId: number) => {
    setSelectedBook(dateId);
    setOpen(true);
  };

  useEffect(() => {
  }, [bookingRow])

  return (
    <>
      {bookingRow?.length &&
        (<div className='mb-[10px] text-center'>
          <TableContainer
            component={Paper}
            style={{ boxShadow: 'none', backgroundColor: 'inherit', borderBottom: 'var(--border-input)' }}>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t("Neme")}</StyledTableCell>
                  <StyledTableCell>{t("Start")}</StyledTableCell>
                  <StyledTableCell>{t("End")}</StyledTableCell>
                  <StyledTableCell align="right" width='8%'>{t('Edit')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookingRow
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((book: any, index: any) => (
                    <TableRow
                      key={book.dateId || index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <StyledTableRow>{book?.user.first_name} {book?.user.last_name}</StyledTableRow>
                      <StyledTableRow>{moment(book?.startTime).format('DD-MM-YYYY')}</StyledTableRow>
                      <StyledTableRow>{moment(book?.endTime).format('DD-MM-YYYY')}</StyledTableRow>
                      <StyledTableRow align="right">
                        <div className='flex gap-[7px] justify-end items-center'>
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            className='text-[14px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                            onClick={() => handleOpen(Number(book.id))} /></div>
                      </StyledTableRow>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={bookingRow.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
           {open && <DeleteModalBookTable
            open={open}
            setOpen={setOpen}
            refresh={refreshBooking}
            selectedDateId={selectedBook}
            setBookingRow={setBookingRow}
            bookingRow={bookingRow}
          />}
        </div>)}
    </>
  );
};

export default BookingTable;