import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';

interface SelectProps {
  selectedTasks: any,
  setSelectedTasks: any,
  test: any
}


const SelectTask: React.FC<SelectProps> = ({ selectedTasks, setSelectedTasks, test }) => {
  const [tasks, setTasks] = useState<any[]>([]);
  const location = useLocation();

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedTasks(selectedOptions);
  };

  useEffect(() => {
    (async () => {
      try {
        if (test) {
          const { data: categoryCheck } = await axios.get(`category/${test}`);
          const formattedTasks = categoryCheck.task.map((task: any) => ({
            value: task.id,
            label: task.name,
          }));
          setTasks(formattedTasks);
        } else {
          setTasks([]);
        }
      } catch (e) {
      }
    })();
  }, [test]);

  useEffect(() => {
    setSelectedTasks([]);
  }, [test]);

  return (
    <Select
      defaultValue={tasks[0]}
      isMulti
      name="colors"
      placeholder={location.pathname === '/reports' ? `${t('AllTasks')}` : `${t('SelectTasksPoins')}`}
      value={selectedTasks}
      onChange={handleSelectChange}
      options={tasks}
      className="basic-multi-select w-full bg-[var(--bg-input)]"
      classNamePrefix="select"
    />
  )
}

export default SelectTask
