import React, { useEffect } from 'react'
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';

const Home = () => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        if (data) {
          dispatch(setAuth(true));
        }
      } catch (e) {
        dispatch(setAuth(false));
      }
    };
    checkAuth();
  }, [dispatch]);
  
  return (
    auth ? <Navigate to='/timesheets'/> : <Navigate to='/login'/>
  )
}

export default Home
