import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import i18next from 'i18next';

interface SelectProps {
  lng: string;
  setLng: React.Dispatch<React.SetStateAction<string>>;
}

const SelectLanguage: React.FC<SelectProps> = ({ lng, setLng }) => {

  const handleChange = (event: SelectChangeEvent) => {
    setLng(event.target.value);
    i18next.changeLanguage(event.target.value);
    localStorage.setItem('language', event.target.value);
  };

  React.useEffect(() => {
    i18next.changeLanguage(`${localStorage.getItem('language')}`)
  }, []);

  return (
    <Box sx={{ minWidth: 70 }}>
      <FormControl fullWidth>
        <Select
          id="demo-simple-select"
          value={lng}
          onChange={handleChange}
        >
          <MenuItem value={'en'}>EN</MenuItem>
          <MenuItem value={'ua'}>UA</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectLanguage;