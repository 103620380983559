import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';

registerLocale('en-GB', enGB);

interface DateRangePickerProps {
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  endDate: Date | null;
  setEndDate: (date: Date | null) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ 
  startDate, 
  setStartDate, 
  endDate, 
  setEndDate }) => {

  return (
    <div className='flex items-center'>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        dateFormat="dd/MM/yyyy"
        className="timesheets-input-all text-right text-[16px] w-[120px] py-[5px] px-[10px] rounded-[5px]"
        locale="en-GB"
      />
      <div className='mx-[10px]'>to</div>
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        dateFormat="dd/MM/yyyy"
        className="timesheets-input-all text-right text-[16px] w-[120px] p-[5px] px-[10px] rounded-[5px]"
        locale="en-GB"
      />
    </div>
  )
}

export default DateRangePicker
