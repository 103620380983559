import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

interface BreadProps {
  locationName: string;
}

const Breadcrums: React.FC<BreadProps> = ({ locationName }) => {

  const formattedPath = locationName.charAt(1).toUpperCase() + locationName.slice(2);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      color="var(--text-color)"
      sx={{
        fontSize: '14px',
        marginBottom: '10px',
        marginLeft: '10px',
        opacity: '.9'
      }}>
      <Link
        underline="hover"
        color="var(--text-color)"
        href="/">
        Home
      </Link>
      <Typography
        color="var(--text-color)"
        sx={{
          fontSize: '14px'
        }}>
        {formattedPath}
      </Typography>
    </Breadcrumbs>
  )
}

export default Breadcrums
