import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Breadcrums from './util/Breadcrums';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';
import EquipmentSchedule from './bookingComponents/EquipmentSchedule';
import AddBooking from './modal/AddBooking';
import Drawer from '@mui/material/Drawer';

interface BookingProps {
  access: any
}

const Booking: React.FC<BookingProps> = ({ access }) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const [locationName, setLocationName] = useState('');
  const [bookingRows, setBookingRows] = useState([]);
  const [range, setRange] = useState();
  const [selectedBooking, setSelectedBooking] = useState<null>(null);
  const [show, setShow] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleShow = (equip: any, all: any) => {
    setSelectedBooking(equip);
    setRange(all);
    setShow(true);
  };

  const refreshBooking = async () => {
    try {
      const { data: bookingRow } = await axios.get('equipment/booking');
      if (bookingRow) {
        setBookingRows(bookingRow);
      }
    } catch (error: any) {
      console.log('error');
    }
  }

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        const { data: bookingRow } = await axios.get('equipment/booking');
        if (data) {
          setLocationName(location.pathname);
          dispatch(setAuth(true));
        }
        setBookingRows(bookingRow);
      } catch (e) {
        dispatch(setAuth(false));
      }
    };
    checkAuth();
  }, [dispatch, location.pathname]);

  useEffect(() => {
  }, [])

  return (
    <>
      <Breadcrums locationName={locationName} />
      {auth && access.booking_get > 0 ?
        (<div>
          <div className="bg-[var(--bg-form)] w-full p-[15px] mb-[15px] rounded-[5px]">
            <h3 className='text-[18px] mb-[20px]'>{t('BookingCalendar')}:</h3>
            <div className="overflow-x-auto">
              <EquipmentSchedule
                bookingRows={bookingRows}
                handleShow={handleShow}
                refreshBooking={refreshBooking} />
            </div>
          </div>
          <Drawer
            anchor="right"
            open={show}
            onClose={() => setShow(false)}
            ModalProps={{ keepMounted: false }}>
            <AddBooking
              setShow={setShow}
              selectedBooking={selectedBooking}
              refreshBooking={refreshBooking}
              range={range} />
          </Drawer>
        </div>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </Box>)}
    </>
  )
}

export default Booking
