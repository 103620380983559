import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import 'dayjs/locale/en-gb';

dayjs.extend(isBetweenPlugin);
dayjs.locale('en-gb');

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(day.day() && {
    color: 'var(--text-color)',
  }),
  ...(day.day() === 1 && {
    borderTopLeftRadius: '50%',
    color: 'var(--text-color)',
    borderBottomLeftRadius: '50%',
  }),
  ...(day.day() === 0 && {
    borderTopRightRadius: '50%',
    color: 'var(--text-color)',
    borderBottomRightRadius: '50%',
  }),
  ...(isSelected && {
    backgroundColor: '#1976d3',
    color: 'white',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    color: 'white',
    backgroundColor: theme.palette.primary[theme.palette.mode],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),

})) as React.ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, 'week');
};

interface WeekPickerProps {
  setWeek: React.Dispatch<React.SetStateAction<Dayjs[]>>;
  setDayPik: any;
}

function Day(
  props: PickersDayProps<Dayjs> & {
    selectedDay?: Dayjs | null;
    hoveredDay?: Dayjs | null;
  },
) {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 3 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    >
    </CustomPickersDay>
  );
}

const WeekPicker: React.FC<WeekPickerProps> = ({ setWeek, setDayPik }) => {
  const [hoveredDay, setHoveredDay] = React.useState<Dayjs | null>(null);
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());

  const getWeekDays = (selectedDay: Dayjs | null): Dayjs[] => {
    if (!selectedDay) {
      return [];
    }
    const startOfWeek = selectedDay.startOf('week');
    const daysOfWeek: Dayjs[] = [];
    for (let i = 0; i < 7; i++) {
      const currentDay = startOfWeek.add(i, 'day');
      daysOfWeek.push(currentDay);
    }
    return daysOfWeek;
  };

  React.useEffect(() => {
    const weekData = getWeekDays(value);
    setWeek(weekData)
  }, [value, setWeek])

  const handleDateChange = (newValue: Dayjs | null) => {
    setValue(newValue);
    if (newValue) {
      setDayPik(newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
      <DateCalendar
        value={value}
        onChange={handleDateChange}
        showDaysOutsideCurrentMonth
        // displayWeekNumber
        slots={{ day: Day }}
        slotProps={{
          day: (ownerState) =>
          ({
            selectedDay: value,
            hoveredDay,
            onPointerEnter: () => setHoveredDay(ownerState.day),
            onPointerLeave: () => setHoveredDay(null),
            onClick: () => setDayPik(ownerState.day)
          } as any),
        }}
      />
    </LocalizationProvider>
  );
}

export default WeekPicker;