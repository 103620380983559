import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';

registerLocale('en-GB', enGB);

interface DateRangePickerProps {
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  endDate: Date | null;
  setEndDate: (date: Date | null) => void;
  bookedRanges: any
}

const DateRangePickerBookin: React.FC<DateRangePickerProps> = ({ 
  startDate, 
  setStartDate, 
  endDate, 
  setEndDate,
  bookedRanges = [] 
}) => {

  const dayClassName = (date: Date) => {
    return excludedRanges.some(
      (range: any) => date >= range.start && date <= range.end
    )
      ? 'booked-range'
      : '';
  };

  const excludedRanges = bookedRanges.map((range: any) => ({
    start: new Date(range.start),
    end: new Date(range.end)
  }));

  return (
    <div className='flex items-center w-full'>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        dateFormat="dd/MM/yyyy"
        className="timesheets-input-all text-right text-[16px] py-[5px] px-[10px] rounded-[5px] w-full"
        locale="en-GB"
        excludeDateIntervals={excludedRanges}
        dayClassName={dayClassName}  
      />
      <div className='mx-[10px]'>to</div>
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        dateFormat="dd/MM/yyyy"
        className="timesheets-input-all text-right text-[16px] p-[5px] px-[10px] rounded-[5px] w-full"
        locale="en-GB"
        excludeDateIntervals={excludedRanges}
        dayClassName={dayClassName}  
      />
    </div>
  );
}

export default DateRangePickerBookin;
