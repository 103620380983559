import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import moment from 'moment';
import TablePagination from '@mui/material/TablePagination';
import { t } from 'i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px',
  backgroundColor: 'var(--btn-user-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

interface ReportsTableProps {
  toils: any;
}

const UsersToils: React.FC<ReportsTableProps> = ({ toils }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(18);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => { 
  }, [toils])

  return (
    <>
      {toils.length &&
        (<div className='mb-[10px] text-center'>
          <TableContainer
            component={Paper}
            style={{ boxShadow: 'none', backgroundColor: 'inherit', borderBottom: 'var(--border-input)' }}>
            <Table sx={{ minWidth: 550 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t('CreatingDate')}</StyledTableCell>
                  <StyledTableCell>{t('TotalHours')}</StyledTableCell>
                  <StyledTableCell>{t('OursPerWeek')}</StyledTableCell>
                  <StyledTableCell>{t('Total')}</StyledTableCell>
                  <StyledTableCell>{t('Description')}</StyledTableCell>
                  <StyledTableCell align="right" width='8%'>{t('Source')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {toils
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user: any, index: any) => (
                    <TableRow
                      key={user.dateId || index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <StyledTableRow
                        component="th"
                        scope="row">
                        {moment(user.createdAt).format('DD.MM.YYYY')}
                      </StyledTableRow>
                      <StyledTableRow>{user.total_hours}</StyledTableRow>
                      <StyledTableRow>{user.hours_per_week}</StyledTableRow>
                      <StyledTableRow>{user.total_sum}</StyledTableRow>
                      <StyledTableRow>{user.description}</StyledTableRow>
                      <StyledTableRow>{user.source}</StyledTableRow>

                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 14, 18]}
            component="div"
            count={toils.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>)}
    </>
  );
};

export default UsersToils;