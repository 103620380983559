import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import TablePagination from '@mui/material/TablePagination';
import { t } from 'i18next';
import moment from 'moment';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px',
  backgroundColor: 'var(--btn-user-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 13
}));

interface ReportsTableProps {
  equip: any;
  handleShow: any;
  access: any;
  sortDirection: any;
  handleShowDelete: any;
  status: any;
  refreshEquip: any;
  selectedStatusFilter: any;
}

const EquipmentTable: React.FC<ReportsTableProps> = ({
  equip,
  handleShow,
  access,
  sortDirection,
  handleShowDelete,
  status,
  refreshEquip,
  selectedStatusFilter }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEquipId, setSelectedEquipId] = useState(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  const handleOpenMenu = (event: any, userId: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedEquipId(userId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedEquipId(null);
  };

  const handleSelectStatus = async (statusId: any) => {
    try {
      const updatedSupervisorId = statusId === -1 ? null : statusId;
      await axios.put(`equipment/${selectedEquipId}`, { statusId: updatedSupervisorId });
      refreshEquip();
    } catch (error) {
      console.error('Error updating supervisor:', error);
    } finally {
      handleCloseMenu();
    }
  };

  const handleRefreshPurch = async (equpId: any) => {
    try {
      const currentDate = new Date();
      const dueDate = new Date();
      dueDate.setFullYear(currentDate.getFullYear() + 1);
      await axios.put(`equipment/${equpId}`, {
        pat_date: currentDate,
        pat_due: dueDate
      });
      refreshEquip();
    } catch (error) {
      console.error('Error updating supervisor:', error);
    } finally {
      handleCloseMenu();
    }
  };

  useEffect(() => {
  }, [equip])

  return (
    <>
      {equip?.length &&
        (<div className='mb-[10px] text-center'>
          <TableContainer
            component={Paper}
            style={{ boxShadow: 'none', backgroundColor: 'inherit', borderBottom: 'var(--border-input)' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t("ID")}</StyledTableCell>
                  <StyledTableCell>{t("Item")}</StyledTableCell>
                  <StyledTableCell>{t("Manufacturer")}</StyledTableCell>
                  <StyledTableCell>{t("Model")}</StyledTableCell>
                  {/* <StyledTableCell>{t("SerialNumber")}</StyledTableCell> */}
                  <StyledTableCell>{t("Category")}</StyledTableCell>
                  <StyledTableCell>{t("Location")}</StyledTableCell>
                  <StyledTableCell>{t("Responsive")}</StyledTableCell>
                  <StyledTableCell>{t("PurchaseDate")}</StyledTableCell>
                  <StyledTableCell>{t("Cost")}</StyledTableCell>
                  <StyledTableCell>{t("PATTestDate")}</StyledTableCell>
                  <StyledTableCell>{t("PATTestDue")}</StyledTableCell>
                  <StyledTableCell>{t("PATTestUser")}</StyledTableCell>
                  <StyledTableCell>{t("Note")}</StyledTableCell>
                  <StyledTableCell>{t("Status")}</StyledTableCell>
                  <StyledTableCell align="right" width='8%'>{t('Edit')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {equip
                  ?.filter((user: any) => selectedStatusFilter === null || user.statusId === selectedStatusFilter)
                  .sort((a: any, b: any) => {
                    const compareValueA = sortDirection === 'asc' ? a.item_name : b.item_name;
                    const compareValueB = sortDirection === 'asc' ? b.item_name : a.item_name;
                    return compareValueA.localeCompare(compareValueB);
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((equip: any, index: any) => (
                    <TableRow
                      key={equip.dateId || index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <StyledTableRow>{equip?.inventory_id}</StyledTableRow>
                      <StyledTableRow>{equip?.item_name}</StyledTableRow>
                      <StyledTableRow>{equip?.manufacturer}</StyledTableRow>
                      <StyledTableRow>{equip?.model}</StyledTableRow>
                      {/* <StyledTableRow>{equip.serial_number}</StyledTableRow> */}
                      <StyledTableRow>{equip.categoryEquipment?.name}</StyledTableRow>
                      <StyledTableRow>{equip.location?.name}</StyledTableRow>
                      <StyledTableRow>{equip?.responsibleUserId && `${equip?.responsibleUser.first_name} ${equip?.responsibleUser.last_name}`}</StyledTableRow>
                      <StyledTableRow>{equip?.purchase_date && moment(equip?.purchase_date).format('DD.MM.YYYY')}</StyledTableRow>
                      <StyledTableRow>{equip?.cost}</StyledTableRow>
                      <StyledTableRow>
                        <div
                          className='flex justify-start items-center gap-[10px]'>
                          <button
                            onClick={() => handleRefreshPurch(equip.id)}
                            className='px-[4px] py-[2px] flex justify-center items-center bg-[var(--btn-user-in)] rounded-[5px]'>
                            <FontAwesomeIcon
                              className='text-[12px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                              icon={faArrowsRotate} />
                          </button>
                          {equip?.pat_date && moment(equip.pat_date).format('DD.MM.YYYY')}
                        </div>
                      </StyledTableRow>
                      <StyledTableRow>{equip?.pat_due && moment(equip.pat_due).format('DD.MM.YYYY')}</StyledTableRow>
                      <StyledTableRow>{equip?.patUserId && `${equip?.patUser.first_name} ${equip?.patUser.last_name}`}</StyledTableRow>
                      <StyledTableRow>{equip?.notes}</StyledTableRow>
                      <StyledTableRow
                        onClick={(event) => handleOpenMenu(event, equip.id)}
                        className='cursor-pointer'>
                        {equip?.statusId ? equip?.status.name : 'none'}
                      </StyledTableRow>
                      <StyledTableRow align="right">
                        <div className='flex gap-[7px] justify-end items-center'>
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                            onClick={() => handleShow(equip)} />
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                            onClick={() => handleShowDelete(equip.id)} /></div>
                      </StyledTableRow>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={equip.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>)}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}>
        <MenuItem
          key={-1}
          onClick={() => handleSelectStatus(-1)}>
          none
        </MenuItem>
        {status.map((status: any) => (
          <MenuItem
            key={status.id}
            onClick={() => handleSelectStatus(status.id)}>
            {status.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default EquipmentTable;