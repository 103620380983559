import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/uk';
import Tooltip from '@mui/material/Tooltip';
import { t } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward, faCalendarPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import DeleteModalAll from '../modal/DeleteModalAll';
import Drawer from '@mui/material/Drawer';
import EditBooking from '../modal/EditBooking';

interface BookingProps {
  bookingRows: any,
  handleShow: any,
  refreshBooking: any,
}

const EquipmentSchedule: React.FC<BookingProps> = ({ bookingRows, handleShow, refreshBooking }) => {
  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
  const [selectedBook, setSelectedBook] = useState<number | null>(null);
  const [range, setRange] = useState();
  const [selectedBooking, setSelectedBooking] = useState<null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const startOfMonth = currentMonth.clone().startOf('month');
  const endOfMonth = currentMonth.clone().endOf('month');
  const daysOfMonth: string[] = [];

  for (let i = 0; i < endOfMonth.date(); i++) {
    daysOfMonth.push(startOfMonth.clone().add(i, 'days').format('YYYY-MM-DD'));
  }

  const isDateBooked = (date: any, bookings: any) => {
    return bookings.some((booking: any) => {
      const bookingStart = moment(booking?.startTime);
      const bookingEnd = moment(booking?.endTime);
      return moment(date).isBetween(bookingStart, bookingEnd, 'day', '[]');
    });
  };

  const getBookingForDay = (day: string, bookings: any) => {
    return bookings.find((booking: any) => {
      const bookingStart = moment(booking?.startTime);
      const bookingEnd = moment(booking?.endTime);
      return moment(day).isBetween(bookingStart, bookingEnd, 'day', '[]');
    });
  };

  const handlePrevMonth = () => {
    setCurrentMonth(currentMonth.clone().subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.clone().add(1, 'month'));
  };

  const handleOpen = (dateId: number) => {
    setSelectedBook(dateId);
    setOpen(true);
  };

  const handleShowEdit = (booking: any, all: any) => {
    setSelectedBooking(booking);
    setRange(all)
    setShow(true);
  };

  return (
    <div>
      {bookingRows.length > 0 ? (
        <>
          <div className="flex flex-col min-w-[1000px] rounded-[3px] overflow-hidden">
            <div className="flex">
              <div className="text-[14px] text-center timesheet-edit-input p-[4px] w-[420px] bg-[var(--background-color)]">
                {t('Equipment')}
              </div>
              <div className="text-[14px] timesheet-edit-input p-[4px] w-[calc(100%-420px)] bg-[var(--background-color)] flex justify-center items-center">
                <div className="flex justify-center gap-[20px] items-center">
                  <button onClick={handlePrevMonth}>
                    <FontAwesomeIcon
                      icon={faBackward}
                      className="cursor-pointer opacity-70 text-[var(--text-color)] hover:opacity-100"
                    />
                  </button>
                  <h2>
                    {t(`${currentMonth.format('MMMM')}`)} {currentMonth.format('YYYY')}
                  </h2>
                  <button onClick={handleNextMonth}>
                    <FontAwesomeIcon
                      icon={faForward}
                      className="cursor-pointer opacity-70 text-[var(--text-color)] hover:opacity-100"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className='w-[420px] flex bg-[var(--background-color)]'>
                <div className="text-[14px] timesheet-edit-input p-[4px] w-[40px]">
                  {t('Edit')}
                </div>
                <div className="text-[14px] timesheet-edit-input p-[4px] w-[60px]">
                  {t('ID')}
                </div>
                <div className="text-[14px] timesheet-edit-input p-[4px] w-[100px]">
                  {t('Name')}
                </div>
                <div className="text-[14px] timesheet-edit-input p-[4px] w-[120px]">
                  {t('Manufacturer')}
                </div>
                <div className="text-[14px] timesheet-edit-input p-[4px] w-[100px]">
                  {t('Model')}
                </div>
              </div>
              {daysOfMonth.map((day, index) => (
                <div
                  key={index}
                  className="text-[12px] w-[calc(100%-420px)] timesheet-edit-input  bg-[var(--bg-form-task)] flex justify-center items-center"
                >
                  <div>{moment(day).format('DD')}</div>
                </div>
              ))}
            </div>
            {bookingRows.map((equipment: any) => (
              <div key={equipment.id} className="flex">
                <div className='w-[420px] flex'>
                  <div className="text-[14px] timesheet-edit-input p-[3px] w-[40px] bg-[var(--bg-form-task)] text-center cursor-pointer">
                    <FontAwesomeIcon
                      icon={faCalendarPlus}
                      className='text-center'
                      onClick={() => handleShow(equipment.id, equipment)} />
                  </div>
                  <div className="text-[14px] timesheet-edit-input p-[3px] w-[60px] bg-[var(--bg-form-task)]">
                    {equipment?.inventory_id}
                  </div>
                  <div className="text-[14px] timesheet-edit-input p-[3px] w-[100px] bg-[var(--bg-form-task)]">
                    {equipment?.item_name}
                  </div>
                  <div className="text-[14px] timesheet-edit-input p-[3px] w-[120px] bg-[var(--bg-form-task)]">
                    {equipment?.manufacturer}
                  </div>
                  <div className="text-[14px] timesheet-edit-input p-[3px] w-[100px] bg-[var(--bg-form-task)]">
                    {equipment?.model}
                  </div>
                </div>
                {daysOfMonth.map((day, index) => {
                  const booking = getBookingForDay(day, equipment.booking);
                  const isBooked = isDateBooked(day, equipment.booking);
                  return (
                    <Tooltip
                      key={index}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: 'bg-[var(--btn-user-btn)]',
                          },
                        },
                      }}
                      title={
                        booking ? (
                          <div
                            className={`relative p-[10px] timesheet-edit-input bg-[var(--btn-user-btn)] rounded-[5px] text-[14px] mb-[-20px] text-[var(--text-color)]`}>
                            <FontAwesomeIcon
                              icon={faXmark}
                              className='absolute top-0 right-[4px] text-right cursor-pointer hover:text-red-500 hover:rotate-180 hover:transform hover:transition-transform duration-300'
                              onClick={() => handleOpen(Number(booking.id))} />
                            <div>
                              <strong>{t('Resp')}:</strong> {booking.client ?
                                booking.clientId :
                                `${booking.user.first_name} ${booking.user.last_name}`}
                            </div>
                            <div>
                              <strong>{t('From')}:</strong> {moment(booking.startTime).format('DD-MM-YYYY')}
                            </div>
                            <div>
                              <strong>{t('To')}:</strong> {moment(booking.endTime).format('DD-MM-YYYY')}
                            </div>
                          </div>
                        ) : (
                          ''
                        )
                      }
                      placement="top"
                    >
                      <div className="pt-[6px] w-[calc(100%-420px)] bg-[var(--btn-user-form)]">
                        <div
                          onClick={() => {
                            if (isBooked && booking) {
                              handleShowEdit(Number(booking.id), Number(booking.equipmentId));
                            }
                          }}
                          className={`h-[80%] ${isDateBooked(day, equipment.booking) ? 'bg-blue-500 cursor-pointer' : ''
                            }`} />
                      </div>
                    </Tooltip>
                  );
                })}
              </div>
            ))}
          </div>
          {open && <DeleteModalAll
            open={open}
            setOpen={setOpen}
            refresh={refreshBooking}
            selectedDateId={selectedBook}
            typeName={'Booking'}
            typeBack={'booking'}
          />}
          <Drawer
            anchor="right"
            open={show}
            onClose={() => setShow(false)}>
            <EditBooking
              setShow={setShow}
              selectedBooking={selectedBooking}
              refreshBooking={refreshBooking}
              range={range} />
          </Drawer>
        </>
      ) : (
        <div className="flex justify-center items-center opacity-50">
          <p>{t('NoBookingsAvailable')}</p>
        </div>
      )}
    </div>
  );
};

export default EquipmentSchedule;
