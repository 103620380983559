import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface DeleteModalProps {
  openDel: boolean,
  setOpenDel: any,
  selectedCatogoryId: any,
  updateTree: any;
}


const DeleteCategoryModal: React.FC<DeleteModalProps> = ({ openDel, setOpenDel, selectedCatogoryId, updateTree }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => setOpenDel(false);
  const [categoryName, setCategoryName] = useState('');
  const handleDeleteAndClose = async () => {
    try {
      await axios.delete(`/category/${selectedCatogoryId}`);
      enqueueSnackbar('Category name updated successfully', { variant: 'success' });
      updateTree();
      handleClose();
    } catch (error) {
      console.error('Error', error);
      enqueueSnackbar('Error', { variant: 'error' });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(`category/${Number(selectedCatogoryId)}`);
        if (data) {
          setCategoryName(data.name)
        }
      } catch (e) {
      }
    })();
  }, [selectedCatogoryId]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDel}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openDel}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2"
              className='text-center'>
              {`${t('AreYouReportCatModal')} "${categoryName}"?`}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}
              className='flex justify-between items-center gap-4'>
              <button
                className='w-full text-[16px] uppercase py-[8px] px-[15px] rounded-[5px] text-white bg-[#1976d2] cursor-pointer border border-black border-opacity-20 hover:bg-[#176bbe]'
                onClick={() => handleDeleteAndClose()}>
                {t('Yes')}</button>
              <button
                className='w-full text-[16px] uppercase py-[8px] px-[15px] rounded-[5px] text-white bg-[#1976d2] cursor-pointer border border-black border-opacity-20 hover:bg-[#176bbe]'
                onClick={() => handleClose()}>
                {t('Cancel')}</button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default DeleteCategoryModal;