import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
import { t } from "i18next";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "var(--text-color)",
  padding: "10px",
  backgroundColor: "var(--background-color)",
  borderBottom: "var(--border-input)",
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px',
  backgroundColor: 'var(--bg-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

interface Row {
  userId: any;
  user: {
    first_name: string;
    last_name: string;
  };
  allTime: string;
}

interface UserReportTableProps {
  rows: Row[];
}

const UserReportTable: React.FC<UserReportTableProps> = ({ rows}) => {
  const aggregateUserTimes = (data: Row[]): Row[] => {
    const aggregatedData: Row[] = [];
    const userIdsProcessed: any = {};

    data.forEach((item) => {
      if (!userIdsProcessed[item.userId]) {
        const totalTimeHours = data
          .filter((row) => row.userId === item.userId)
          .reduce((acc, curr) => {
            const [hours, minutes] = curr.allTime.split(":").map(Number);
          return acc + hours + minutes / 60; 
        }, 0);

        aggregatedData.push({
          userId: item.userId,
          user: item.user,
          allTime: formatHours(totalTimeHours),
        });

        userIdsProcessed[item.userId] = true;
      }
    });

    return aggregatedData;
  };

  const formatHours = (totalHours: number): string => {
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);
    return `${hours}:${minutes.toString().padStart(2, "0")}`;
  };

  return (
    <>
      {rows.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("Name")}</StyledTableCell>
                <StyledTableCell align="right">{t("Total Hours")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aggregateUserTimes(rows).map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <StyledTableRow>{`${row.user.first_name} ${row.user.last_name}`}</StyledTableRow>
                  <StyledTableRow align="right">{row.allTime}</StyledTableRow>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default UserReportTable;
