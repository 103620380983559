import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import moment from 'moment';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import 'moment-timezone';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AproveBack from '../modal/AproveBack';
// add anchors
import { v4 as uuidv4 } from 'uuid';
import NotisReject from '../modal/NotisReject';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px',
  backgroundColor: 'var(--bg-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

interface Row {
  userId: any;
  dateId: number;
  date: string;
  startTime: string;
  endTime: string;
  categoryid: any;
  description: string;
  allTime: string;
  tasks: any;
  userName: string;
  category: string;
  categoryTree: string;
  can_approve: boolean;
  superVisor: string;
}

interface ReportsTableProps {
  rows: Row[];
  updateRowsAfterStatusChange: any;
  loading: boolean;
  setLoading: any;
  refreshUserHeder: any;
}

const ReportsTableAprove: React.FC<ReportsTableProps> = ({
  rows,
  updateRowsAfterStatusChange,
  loading,
  setLoading,
  refreshUserHeder }) => {
  const [processingReportId, setProcessingReportId] = useState<number | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [reportRows, setReportRows] = useState<Row[]>(rows);
  const [showAprove, setShowAprove] = useState(false);
  const [showReject, setReject] = useState(false);
  const [messege, setMessege] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [rejectRow, setRejectRow] = useState<Row | null>(null);
  const [tableVisibility, setTableVisibility] = useState<{ [userId: string]: boolean }>({});
  // add anchors
  const [userAnchors, setUserAnchors] = useState<{ [userId: string]: string }>({});

  useEffect(() => {
    const initialVisibility: { [userId: string]: boolean } = {};
    // add anchors
    const anchors: { [userId: string]: string } = {};
    rows.forEach(row => {
      initialVisibility[row.userId] = false;
      // add anchors
      anchors[row.userId] = `anchor-${uuidv4()}`;
    });
    setTableVisibility(initialVisibility);
    // add anchors
    setUserAnchors(anchors);
    setReportRows(rows);
  }, [rows]);

  const handleOpenAproveModal = (reportId: any, week: any) => {
    setSelectedReportId(reportId);
    setSelectedWeek(week);
    setShowAprove(true);
  };

  const handleOpenRejectModal = (reportId: any, userId: any, row: any) => {
    setRejectRow(row);
    setSelectedReportId(reportId);
    setSelectedUserId(userId);
    setMessege('');
    setReject(true);
  };

  const sortedReportRows = useMemo(() => {
    return [...reportRows].sort((a, b) => a.userName.localeCompare(b.userName));
  }, [reportRows]);

  const users = useMemo(() => {
    const groupedUsers: { [userId: string]: { [week: string]: Row[] } } = {};
    sortedReportRows.forEach(row => {
      const week = moment(row.date).isoWeek();
      const year = moment(row.date).year();
      const weekYear = `${year}-W${week}`;
      if (!groupedUsers[row.userId]) {
        groupedUsers[row.userId] = {};
      }
      if (!groupedUsers[row.userId][weekYear]) {
        groupedUsers[row.userId][weekYear] = [];
      }
      groupedUsers[row.userId][weekYear].push(row);
    });
    return groupedUsers;
  }, [reportRows]);

  const changeReportStatus = useCallback(async (dateId: number) => {
    setProcessingReportId(dateId);
    setLoading(true);
    try {
      await axios.put('/time-reports/updateStatus', { reportsId: [dateId], status: 'fulfilled' });
      enqueueSnackbar(`Report has been approved successfully`, { variant: 'success' });
      setReportRows((prevRows) => prevRows.filter(row => row.dateId !== dateId));
    } catch (error) {
      console.error(`Error changing status of report with ID ${dateId}:`, error);
      enqueueSnackbar(`Error changing status of report with ID ${dateId}`, { variant: 'error' });
    } finally {
      //go to anchors
      const userId = rows.find(row => row.dateId === dateId)?.userId;
      if (userId) {
        const anchorId = userAnchors[userId];
        const element = document.getElementById(anchorId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
      setProcessingReportId(null);
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  const rejectReport = useCallback(async (dateId: number) => {
    if (!messege.trim()) {
      enqueueSnackbar(`Messege field is emty`, { variant: 'error' });
      return;
    }
    try {
      setProcessingReportId(dateId);
      setLoading(true);
      await axios.post(`notice/add`, {
        message: messege,
        source: `Reject report: date: ${moment(rejectRow?.date).format('DD.MM.YYYY')}, time: ${moment.utc(rejectRow?.startTime).format('HH:mm')}-${moment.utc(rejectRow?.endTime).format('HH:mm')} category: ${rejectRow?.categoryTree}, hours: ${rejectRow?.allTime} `,
        sourceId: dateId,
        severity: 'warning',
        userId: selectedUserId
      });
      await axios.put('/time-reports/updateStatus', { reportsId: [dateId], status: 'rejected' });
      setReportRows((prevRows) => prevRows.filter(row => row.dateId !== dateId));
      enqueueSnackbar(`Report has been rejected`, { variant: 'success' });
    } catch (error) {
      console.error(`Error changing status of report with ID ${dateId}:`, error);
      enqueueSnackbar(`Error changing status of report with ID ${dateId}`, { variant: 'error' });
    } finally {
      setProcessingReportId(null);
      setLoading(false);
      setReject(false);
      refreshUserHeder();
    }
  }, [enqueueSnackbar, reportRows, messege]);

  const approveAllReportsForWeek = useCallback(
    async (userId: any, week: string) => {
      setLoading(true);
      const userReports = rows.filter((row) => Number(row.userId) === Number(userId) && `${moment(row.date).year()}-W${moment(row.date).isoWeek()}` === week);
      try {
        const promises = userReports.map(async (report) => {
          if (report.can_approve) {
            await axios.put('/time-reports/updateStatus', { reportsId: [report.dateId], status: 'fulfilled' });
            return report.dateId;
          }
          return null;
        });
        const approvedReportIds = (await Promise.all(promises)).filter((id) => id !== null);
        enqueueSnackbar(`Approved ${approvedReportIds.length} out of ${userReports.length} reports for user ${userReports[0]?.userName} for week ${week}`, { variant: 'success' });
        // updateRowsAfterStatusChange();
        setReportRows((prevRows) => prevRows.filter(row => !approvedReportIds.includes(row.dateId)));
      } catch (error) {
        console.error('Error approving reports for week:', error);
        enqueueSnackbar(`Error approving some reports for user ${userReports[0]?.userName} for week ${week}`, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, rows, updateRowsAfterStatusChange]
  );

  const BackReportsForWeek = useCallback(
    async (userId: any, week: string) => {
      setLoading(true);
      const userReports = rows.filter((row) => Number(row.userId) === Number(userId) && `${moment(row.date).year()}-W${moment(row.date).isoWeek()}` === week);
      const canApproveAll = userReports.every(report => report.can_approve);
      if (!canApproveAll) {
        enqueueSnackbar(`Cannot approve all reports for user ${userReports[0]?.userName} for week ${week}. Some reports cannot be approved.`, { variant: 'error' });
        setLoading(false);
        return;
      }
      try {
        const reportIdsToChange = userReports.map(report => report.dateId);
        const response = await axios.put('/time-reports/updateStatus', { reportsId: reportIdsToChange, status: 'new' });
        if (response.status === 200) {
          setReportRows((prevRows) =>
            prevRows.filter((row) => !reportIdsToChange.includes(row.dateId))
          )
          setLoading(false);
          enqueueSnackbar(`All reports for user ${userReports[0]?.userName} for week ${week} have been back to new`, { variant: 'success' });
        } else {
          enqueueSnackbar(`Can't back week`, { variant: 'error' });
          setLoading(false);
          return
        }
      } catch (error) {
        console.error('Error back reports for week:', error);
        enqueueSnackbar(`Error back reports for user ${userReports[0]?.userName} for week ${week}`, { variant: 'error' });
      } finally {
        setShowAprove(false);
      }
    },
    [enqueueSnackbar, rows]
  );

  const calculateTotalTime = useCallback((userRows: Row[]) => {
    return userRows
      .reduce((total, row) => {
        const [hours, minutes] = row.allTime.split(':').map(parseFloat);
        return total + hours + minutes / 60;
      }, 0)
      .toFixed(2);
  }, []);

  const calculateWeeklyTotalTime = useCallback((userRows: Row[]) => {
    const weeklyTotalTimes: { [week: string]: number } = {};
    userRows.forEach(row => {
      const week = moment(row.date).isoWeek();
      const year = moment(row.date).year();
      const weekYear = `${year}-W${week}`;
      if (!weeklyTotalTimes[weekYear]) {
        weeklyTotalTimes[weekYear] = 0;
      }
      const [hours, minutes] = row.allTime.split(':').map(parseFloat);
      weeklyTotalTimes[weekYear] += hours + minutes / 60;
    });
    return weeklyTotalTimes;
  }, []);

  const formatTotalTime = useCallback((totalTime: any) => {
    const hours = Math.floor(totalTime);
    const minutes = Math.round((totalTime - hours) * 60);
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }, []);

  const renderUserReports = useCallback(() => {
    const sortedUsers = Object.entries(users).sort(([userIdA, weeksA], [userIdB, weeksB]) => {
      const userNameA = Object.values(weeksA)[0][0].userName;
      const userNameB = Object.values(weeksB)[0][0].userName;
      return userNameA.localeCompare(userNameB);
    });
    return sortedUsers.map(([userId, weeks]) => {
      const allUserRows = Object.values(weeks).flat();
      return (
        <div
          key={userId}
          // add anchors
          id={userAnchors[userId]}
          className={`text-center rounded-[5px] mb-[10px] px-[10px] bg-[var(--bg-aprove)] user-box-view-all ${tableVisibility[userId] ? 'pb-[10px]' : 'hover:bg-[var(--bg-header-fix-block)]'}`}
        >
          <div
            className='flex justify-between items-center p-[10px] cursor-pointer'
            onClick={() =>
              setTableVisibility((prevState) => {
                const newState = Object.keys(prevState).reduce((acc: any, key) => {
                  acc[key] = key === userId ? !prevState[key] : false;
                  return acc;
                }, {});

                return newState;
              })
            }
          >
            <h3>{Object.values(weeks)[0][0].userName}</h3>
            <div className='flex items-center gap-3 justify-normal'>
              <div>
                {t('Total')}: <span className='font-semibold'>{formatTotalTime(calculateTotalTime(allUserRows))}</span>
              </div>
              <FontAwesomeIcon
                icon={faArrowUp}
                className={`cursor-pointer opacity-70 px-[8px] py-[5px] rounded-full transition-[1s] hover:bg-gray-500 hover:text-white ${tableVisibility[userId] ? 'rotate-0' : 'rotate-180'}`}
              />
            </div>
          </div>
          {tableVisibility[userId] && (
            <div className='user-box-view-all rounded-[5px]'>
              {Object.entries(weeks).map(([week, userRows]) => (
                <div key={week}>
                  <div className='flex items-center font-bold gap-[10px] justify-start'>
                    <h4 className='text-right px-[5px] bg-[var(--background-color)] font-bold'>
                      {`${moment(userRows[0].date).startOf('isoWeek').format('DD.MM.YYYY')} - ${moment(userRows[0].date).endOf('isoWeek').format('DD.MM.YYYY')}`}
                    </h4>
                  </div>
                  <TableContainer
                    component={Paper}
                    style={{
                      boxShadow: 'none',
                      backgroundColor: 'inherit',
                      borderBottom: 'var(--border-input)',
                    }}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>{t('Name')}</StyledTableCell>
                          <StyledTableCell>{t('Date')}</StyledTableCell>
                          <StyledTableCell>{t('StartTime')}</StyledTableCell>
                          <StyledTableCell>{t('EndTime')}</StyledTableCell>
                          <StyledTableCell>{t('Category')}</StyledTableCell>
                          <StyledTableCell>{t('Task')}</StyledTableCell>
                          <StyledTableCell>{t('Supervisor')}</StyledTableCell>
                          <StyledTableCell>{t('Description')}</StyledTableCell>
                          <StyledTableCell align='right'>{t('Hours')}</StyledTableCell>
                          <StyledTableCell align='right' width='6%'>{t('Approve')}</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userRows.map((row, index) => (
                          <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <StyledTableRow>{row.userName}</StyledTableRow>
                            <StyledTableRow>{moment(row.date).format('DD.MM.YYYY')}</StyledTableRow>
                            <StyledTableRow>{moment.utc(row.startTime).format('HH:mm')}</StyledTableRow>
                            <StyledTableRow>{moment.utc(row.endTime).format('HH:mm')}</StyledTableRow>
                            <StyledTableRow>{row.categoryTree}</StyledTableRow>
                            <StyledTableRow>
                              {row.tasks ? row.tasks.map((task: any) => task.name).join(', ') : null}
                            </StyledTableRow>
                            <StyledTableRow>{row.superVisor}</StyledTableRow>
                            <StyledTableRow>{row.description}</StyledTableRow>
                            <StyledTableRow align='right'>{row.allTime}</StyledTableRow>
                            <StyledTableRow align='right'>
                              <div className='flex gap-2 item-center'>
                                <button
                                  className={`border-none text-[16px] rounded-[5px] py-[3px] px-[10px] ${row.can_approve ? 'bg-[#1976d2] cursor-pointer text-white hover:bg-[#176bbe]' : 'bg-[#32485e] text-gray-300 cursor-default'} `}
                                  onClick={() => changeReportStatus(Number(row.dateId))}
                                  disabled={processingReportId === row.dateId || !row.can_approve}
                                >
                                  {t('Approve')}
                                </button>
                                <button
                                  className={`border-none text-[16px] rounded-[5px] py-[3px] px-[10px] ${row.can_approve ? 'bg-[#d21944] text-white cursor-pointer hover:bg-[#be1730]' : 'bg-[#823e4e] text-gray-300 cursor-default'} `}
                                  onClick={() => handleOpenRejectModal(Number(row.dateId), Number(row.userId), row)}
                                  disabled={processingReportId === row.dateId || !row.can_approve}
                                >
                                  {t('Return')}
                                </button>
                              </div>
                            </StyledTableRow>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className='flex items-center gap-[10px] justify-between p-[15px] pb-[20px] aprove-border-table w-full'>
                    <div>
                      <Button
                        variant="outlined"
                        sx={{
                          fontSize: 13,
                          textTransform: 'none'
                        }}
                        onClick={() => handleOpenAproveModal(userId, week)}
                        disabled={loading}>
                        {t('RemoveAllForWeek')}
                      </Button>
                    </div>
                    <div className='flex items-center gap-[10px]'>
                      <div>
                        {t('TotalWeek')}: {formatTotalTime(calculateWeeklyTotalTime(userRows)[week])}
                      </div>
                      <button
                        className='border-none text-[16px] rounded-[5px] py-[3px] px-[10px] cursor-pointer bg-[#1976d2] text-white hover:bg-[#176bbe]'
                        onClick={() => approveAllReportsForWeek(userId, week)}
                        disabled={loading}>
                        {t('ApproveAllForWeek')}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    });
  }, [
    approveAllReportsForWeek,
    calculateTotalTime,
    changeReportStatus,
    formatTotalTime,
    rejectReport,
    tableVisibility,
    users,
    processingReportId,
  ]);

  return <div>
    {loading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Box>
    ) : (
      renderUserReports()
    )}
    {showReject &&
      <NotisReject
        showReject={showReject}
        setReject={setReject}
        rejectReport={rejectReport}
        reportId={selectedReportId}
        setMessege={setMessege} />}
    {showAprove &&
      <AproveBack
        showAprove={showAprove}
        setShowAprove={setShowAprove}
        BackReportsForWeek={BackReportsForWeek}
        reportId={selectedReportId}
        week={selectedWeek} />}
  </div>;
};

export default ReportsTableAprove;
