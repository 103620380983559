import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableList, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import { t } from 'i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px',
  backgroundColor: 'var(--btn-user-form)',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

interface ReportsTableProps {
  users: any;
  handleShow: any;
  handleShowToils: any;
  selectedContractFilter: any;
  access: any;
  baseUrl: string;
}

const UsersToils: React.FC<ReportsTableProps> = ({
  users, handleShow, handleShowToils, selectedContractFilter, access, baseUrl }) => {
  const [page, setPage] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState('first_name');
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const convertToHoursAndMinutes = (totalToils: number) => {
    const isNegative = totalToils < 0;
    const absoluteTotalToils = Math.abs(totalToils);
    const hours = Math.trunc(absoluteTotalToils);
    const minutes = Math.round((absoluteTotalToils - hours) * 60);
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${isNegative ? '-' : ''}${hours}:${formattedMinutes}`;
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
  }, [users])

  return (
    <>
      {users.length &&
        (<div className='mb-[10px] text-center'>
          <TableContainer
            component={Paper}
            style={{ boxShadow: 'none', backgroundColor: 'inherit', borderBottom: 'var(--border-input)' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel
                      active={orderBy === 'first_name'}
                      direction={orderBy === 'first_name' ? order : 'asc'}
                      onClick={() => handleRequestSort('first_name')}>
                      {t('Name')}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>{t('ContractType')}</StyledTableCell>
                  <StyledTableCell>{t('ContractedHoursPerWeek')}</StyledTableCell>
                  <StyledTableCell>{t('TotalTOIL')}</StyledTableCell>
                  <StyledTableCell align="right" width='8%'>{t('Edit')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  ?.filter((user: any) => selectedContractFilter === null || user.contractTypeId === selectedContractFilter)
                  .sort((a: any, b: any) => {
                    const compareValueA = order === 'asc' ? a.first_name : b.first_name;
                    const compareValueB = order === 'asc' ? b.first_name : a.first_name;
                    return compareValueA.localeCompare(compareValueB);
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user: any, index: any) => (
                    <TableRow
                      key={user.dateId || index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <StyledTableRow
                        component="th"
                        scope="row">
                        {<div className="flex gap-[10px] items-center cursor-pointer"
                          onClick={() => handleShow(user)}>
                          {user.avatarUrl ?
                            <div
                              className='w-[30px] h-[30px] cursor-pointer rounded-full'>
                              <img
                                className="w-full h-full object-cover rounded-full"
                                src={`${baseUrl}${user.avatarUrl}`} alt="avatar" />
                            </div>
                            :
                            <div
                              className="flex justify-center items-center  text-white w-[30px] h-[30px] text-[14px] rounded-[50%] uppercase"
                              style={{ backgroundColor: `${user.role?.color}` }}>
                              {user.first_name[0]}{user.last_name[0]}</div>}
                          <div className='flex gap-[5px] item-center flex-wrap'>
                            <div className="user-box-name">{user.first_name}</div>
                            <div className="user-box last">{user.last_name}</div>
                            <div className="user-box second">{user.second_name}</div>
                          </div>
                        </div>}
                      </StyledTableRow>
                      <StyledTableRow>{user.contractType?.title}</StyledTableRow>
                      <StyledTableRow>{convertToHoursAndMinutes(user.hoursPerWeek)}</StyledTableRow>
                      <StyledTableRow>{convertToHoursAndMinutes(user.totalToils)}</StyledTableRow>
                      <StyledTableRow align="right">
                        <div className='flex gap-[7px] justify-end items-center'>
                          {access.toil_do > 0 ? (
                            <FontAwesomeIcon
                              icon={faStopwatch}
                              className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                              onClick={() => handleShowToils(user)} />) : ''}
                          <FontAwesomeIcon
                            icon={faTableList}
                            className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                            onClick={() => handleShow(user)} /></div>
                      </StyledTableRow>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>)}
    </>
  );
};

export default UsersToils;