import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface AddModalProps {
  openAddRole: boolean,
  setOpenAddRole: any,
  refrefhRole: any,
  openCheckModal: any,
  setCheckOpenModal: any,
}


const AddRole: React.FC<AddModalProps> = ({
  openAddRole,
  setOpenAddRole,
  refrefhRole,
  openCheckModal,
  setCheckOpenModal }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [nameRole, setNameRole] = useState('');
  const handleClose = () => {
    setOpenAddRole(false);
    setCheckOpenModal(false);
  };

  const handleAddRole = async () => {
    try {
      if (!nameRole || !nameRole.trim()) {
        enqueueSnackbar('Add name Role', { variant: 'error' });
        return
      }

      const data: { title: string } = {
        title: nameRole
      };

      await axios.post('roles/add', data);
      setNameRole('')
      refrefhRole();
      setCheckOpenModal(false);
      handleClose();
      enqueueSnackbar(`Add Role ${nameRole}`, { variant: 'success' });
    } catch (error: any) {
      console.error('Error', error);
      enqueueSnackbar('Error', { variant: 'error' });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openAddRole}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openAddRole}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2"
              className='text-center'>
              <div className='mb-[10px] text-[18px]'>{t('AddRole')}</div>
              <div>
                <input
                  className='w-full place-hold text-[22px] py-[4px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                  type="text"
                  placeholder={t('EnterNameOfRole')}
                  value={nameRole}
                  onChange={(e) => setNameRole(e.target.value)} />
              </div>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}
              className='flex justify-between items-center gap-4'>
              <button
                className='w-full text-[16px] uppercase py-[8px] px-[15px] rounded-[5px] text-white bg-[#1976d2] cursor-pointer border border-black border-opacity-20 hover:bg-[#176bbe]'
                onClick={() => handleAddRole()}>
                {t('Add')}</button>
              <button
                className='w-full text-[16px] uppercase py-[8px] px-[15px] rounded-[5px] text-white bg-[#1976d2] cursor-pointer border border-black border-opacity-20 hover:bg-[#176bbe]'
                onClick={() => handleClose()}>
                {t('Cancel')}</button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default AddRole;