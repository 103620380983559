import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

interface SelectProps {
  selectedTask: any,
  setSelectedTask: any,
  category: any
}


const SelectTaskEdit: React.FC<SelectProps> = ({selectedTask, setSelectedTask, category }) => {
  const [task, setTask] = useState<any[]>([]);
  const [selectTask, setSelectTask] = useState<any[]>([]);

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedTask(selectedOptions);
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: taskData } = await axios.get('task');
        const sortedCategories = taskData.sort((a: any, b: any) => a.name.localeCompare(b.name));
        const formattedCategory = sortedCategories.map((task: any) => ({
          value: task.id,
          label: task.name,
        }));
        setTask(formattedCategory);
      } catch (e) {
      }
    })();
  }, []);

  useEffect(() => {
    if (category && category.task) {
      const formattedTasks = category.task.map((task: any) => ({
        value: task.id,
        label: task.name,
      }));
      setSelectTask(formattedTasks);
      setSelectedTask(selectTask)
    }
  }, [category]);

  useEffect(() => {
    setSelectTask(selectedTask);
  }, [selectedTask]);

  return (
    <Select
      isMulti
      closeMenuOnSelect={false}
      name="colors"
      placeholder='Select Tasks...'
      value={selectTask}
      onChange={handleSelectChange}
      options={task}
      className="basic-multi-select w-full task-edit-fix bg-[var(--bg-input)] text-left"
      classNamePrefix="select"
    />
  )
}

export default SelectTaskEdit
