import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

interface SelectProps {
  selectedTask: any,
  setSelectedTask: any
}


const SelectTaskAdmin: React.FC<SelectProps> = ({selectedTask, setSelectedTask }) => {
  const [task, setTask] = useState<any[]>([]);

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedTask(selectedOptions);
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: taskData } = await axios.get('task');
        const sortedCategories = taskData.sort((a: any, b: any) => a.name.localeCompare(b.name));
        const formattedCategory = sortedCategories.map((task: any) => ({
          value: task.id,
          label: task.name,
        }));
        setTask(formattedCategory);
      } catch (e) {
      }
    })();
  }, []);

  return (
    <Select
      defaultValue={task[0]}
      closeMenuOnSelect={false}
      isMulti
      name="colors"
      placeholder='Select Tasks...'
      value={selectedTask}
      onChange={handleSelectChange}
      options={task}
      className="basic-multi-select w-full task-edit-fix bg-[var(--bg-input)] text-left"
      classNamePrefix="select"
    />
  )
}

export default SelectTaskAdmin
