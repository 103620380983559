import React, { useState, useMemo, useCallback } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
import moment from "moment";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../modal/DeleteModal";
import Drawer from "@mui/material/Drawer";
import EditReports from "../modal/EditReports";
import { useSnackbar } from "notistack";
import { t } from "i18next";
import 'moment-timezone';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "var(--text-color)",
  padding: "10px",
  backgroundColor: "var(--background-color)",
  borderBottom: "var(--border-input)",
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: "var(--text-color)",
  padding: "7px",
  borderBottom: "var(--border-input)",
  fontSize: 14,
}));

interface Row {
  userId: any;
  dateId: number;
  date: string;
  startTime: string;
  endTime: string;
  categoryid: any;
  description: string;
  allTime: string;
  tasks: any;
  status: any;
  userName: string;
  user: {
    first_name: string;
    last_name: string;
  };
  categoryTree: string;
  superVisor: string;
}

interface AproveTableProps {
  rows: Row[];
  updateReports: any;
  openCheckModal: boolean;
  setCheckOpenModal: any;
  access: any;
  selectedSortOption: string;
}

const ApproveTable: React.FC<AproveTableProps> = ({
  rows,
  access,
  updateReports,
  openCheckModal,
  setCheckOpenModal,
  selectedSortOption
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedDateId, setSelectedDateId] = React.useState<number | null>(null);
  const [selectedDescription, setSelectedDescription] = React.useState<any | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<any | null>(null);
  const [selectedDateFrom, setSelectedDateFrom] = React.useState<any | null>(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState<any | null>(null);
  const [tableVisibility, setTableVisibility] = useState<{ [key: string]: boolean }>({});

  const handleOpen = (dateId: number) => {
    setSelectedDateId(dateId);
    setOpen(true);
  };

  const sortedRows = useMemo(() => {
    return rows?.sort((a, b) => {
      if (selectedSortOption === 'user') {
        return a.user.first_name.localeCompare(b.user.first_name);
      } else if (selectedSortOption === 'category') {
        return a.categoryTree.localeCompare(b.categoryTree);
      }
      return 0;
    });
  }, [rows, selectedSortOption]);

  const usersOrCategories = useMemo(() => {
    const groupedData: { [key: string]: { [week: string]: Row[] } } = {};
    sortedRows.forEach(row => {
      const key = selectedSortOption === 'user' ? row.userId : row.categoryTree;
      const week = moment(row.date).isoWeek();
      const year = moment(row.date).year();
      const weekYear = `${year}-W${week}`;
      if (!groupedData[key]) {
        groupedData[key] = {};
      }
      if (!groupedData[key][weekYear]) {
        groupedData[key][weekYear] = [];
      }
      groupedData[key][weekYear].push(row);
    });
    return groupedData;
  }, [sortedRows, selectedSortOption]);

  const calculateTotalTime = useCallback((userRows: Row[]) => {
    return userRows
      .reduce((total, row) => {
        const [hours, minutes] = row.allTime.split(':').map(parseFloat);
        return total + hours + minutes / 60;
      }, 0)
      .toFixed(2);
  }, []);

  const calculateWeeklyTotalTime = useCallback((userRows: Row[]) => {
    const weeklyTotalTimes: { [week: string]: number } = {};
    userRows.forEach(row => {
      const week = moment(row.date).isoWeek();
      const year = moment(row.date).year();
      const weekYear = `${year}-W${week}`;
      if (!weeklyTotalTimes[weekYear]) {
        weeklyTotalTimes[weekYear] = 0;
      }
      const [hours, minutes] = row.allTime.split(':').map(parseFloat);
      weeklyTotalTimes[weekYear] += hours + minutes / 60;
    });
    return weeklyTotalTimes;
  }, []);

  const formatTotalTime = useCallback((totalTime: any) => {
    const hours = Math.floor(totalTime);
    const minutes = Math.round((totalTime - hours) * 60);
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }, []);

  const handleDeleteReport = async (dateId: number) => {
    try {
      const response = await axios.post(`time-reports/deleteReports/${dateId}`);
      enqueueSnackbar(response.data.message, { variant: "success" });
    } catch (error: any) {
      console.error("Error deleting report:", error.message);
      enqueueSnackbar("Error updating report", { variant: "error" });
    }
  };

  const handleOpenEdit = (
    dateId: number,
    description: any,
    date: any,
    startTime: any,
    endTime: any
  ) => {
    setSelectedDateId(dateId);
    setSelectedDescription(description);
    setSelectedDate(date);
    setSelectedDateFrom(startTime);
    setSelectedDateTo(endTime);
    setOpenEdit(true);
    setCheckOpenModal(true);
  };

  const renderUserReports = useCallback(() => {
    return Object.entries(usersOrCategories).map(([key, weeks]) => {
      const allUserRows = Object.values(weeks).flat();
      return (
        <div
          key={key}
          className={`text-center rounded-[5px] mb-[10px] px-[10px] bg-[var(--bg-aprove)] user-box-view-all  ${tableVisibility[key] ? 'pb-[10px]' : 'hover:bg-[var(--bg-header-fix-block)]'}`}>
          <div
            onClick={() =>
              setTableVisibility((prevState) => ({
                ...prevState,
                [key]: !prevState[key],
              }))
            }
            className='flex cursor-pointer justify-between items-center p-[10px]'>
            <h3>{selectedSortOption === 'user' ? Object.values(weeks)[0][0].userName : key}</h3>
            <div className='flex items-center gap-3 justify-normal'>
              <div>
                {t('Total')}: <span className='font-semibold'>{formatTotalTime(calculateTotalTime(allUserRows))}</span>
              </div>
              <FontAwesomeIcon
                icon={faArrowUp}
                className={`cursor-pointer opacity-70 px-[8px] py-[5px] rounded-full transition-[1s] hover:bg-gray-500 hover:text-white ${tableVisibility[key] ? 'rotate-0' : 'rotate-180'}`}
              />
            </div>
          </div>
          {tableVisibility[key] && (
            <div className='user-box-view-all rounded-[5px]'>
              {Object.entries(weeks).map(([week, userRows]) => (
                <div key={week}>
                  <div className='flex items-center font-bold gap-[10px] justify-start'>
                    <h4 className='text-right px-[5px] bg-[var(--background-color)] font-bold'>
                      {`${moment(userRows[0].date).startOf('isoWeek').format('DD.MM.YYYY')} - ${moment(userRows[0].date).endOf('isoWeek').format('DD.MM.YYYY')}`}
                    </h4>
                  </div>
                  <TableContainer
                    component={Paper}
                    style={{
                      boxShadow: 'none',
                      backgroundColor: 'inherit',
                      borderBottom: 'var(--border-input)',
                    }}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>{t('Name')}</StyledTableCell>
                          <StyledTableCell>{t('Date')}</StyledTableCell>
                          <StyledTableCell>{t('StartTime')}</StyledTableCell>
                          <StyledTableCell>{t('EndTime')}</StyledTableCell>
                          <StyledTableCell>{t('Category')}</StyledTableCell>
                          <StyledTableCell>{t('Task')}</StyledTableCell>
                          <StyledTableCell>{t('Supervisor')}</StyledTableCell>
                          <StyledTableCell>{t('Description')}</StyledTableCell>
                          <StyledTableCell>{t('StatusReport')}</StyledTableCell>
                          <StyledTableCell align='right'>{t('Hours')}</StyledTableCell>
                          {access.reports_do >= 2 && <StyledTableCell align='right' width='6%'>{t('Edit')}</StyledTableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userRows.map((row, index) => (
                          <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <StyledTableRow>{row.userName}</StyledTableRow>
                            <StyledTableRow>{moment(row.date).format('DD.MM.YYYY')}</StyledTableRow>
                            <StyledTableRow>{moment.utc(row.startTime).format('HH:mm')}</StyledTableRow>
                            <StyledTableRow>{moment.utc(row.endTime).format('HH:mm')}</StyledTableRow>
                            <StyledTableRow>{row.categoryTree}</StyledTableRow>
                            <StyledTableRow>
                              {row.tasks ? row.tasks.map((task: any) => task.name).join(', ') : null}
                            </StyledTableRow>
                            <StyledTableRow>{row.superVisor}</StyledTableRow>
                            <StyledTableRow>{row.description}</StyledTableRow>
                            <StyledTableRow>{row.status}</StyledTableRow>
                            <StyledTableRow align='right'>{row.allTime}</StyledTableRow>
                            {access.reports_do >= 2 ? (
                              <StyledTableRow align="right">
                                <div className="flex justify-end items-center">
                                  {access.reports_do >= 2 ? (
                                    <FontAwesomeIcon
                                      icon={faPenToSquare}
                                      className="text-[16px] mr-[8px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70"
                                      onClick={() =>
                                        handleOpenEdit(
                                          row.dateId,
                                          row.description,
                                          row.date,
                                          row.startTime,
                                          row.endTime
                                        )
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {access.reports_do > 2 ? (
                                    <FontAwesomeIcon
                                      icon={faTrashCan}
                                      className="text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70"
                                      onClick={() => handleOpen(row.dateId)}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </StyledTableRow>
                            ) : (
                              ""
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className='flex items-center gap-[10px] justify-end p-[10px] pb-[20px] aprove-border-table'>
                    <div>
                      {t('TotalWeek')}: {formatTotalTime(calculateWeeklyTotalTime(userRows)[week])}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <DeleteModal
            open={open}
            setOpen={setOpen}
            handleDeleteReport={handleDeleteReport}
            selectedDateId={selectedDateId}
            updateReports={updateReports}
          />
          <Drawer anchor="right" open={openEdit} onClose={() => setOpenEdit(false)}>
            <EditReports
              openCheckModal={openCheckModal}
              setCheckOpenModal={setCheckOpenModal}
              setOpenEdit={setOpenEdit}
              selectedDateId={selectedDateId}
              selectedDescription={selectedDescription}
              selectedDate={selectedDate}
              selectedDateFrom={selectedDateFrom}
              selectedDateTo={selectedDateTo}
              updateReports={updateReports}
            />
          </Drawer>
        </div >
      );
    });
  }, [
    calculateTotalTime,
    formatTotalTime,
    handleOpenEdit,
    tableVisibility,
    handleDeleteReport,
    usersOrCategories,
    selectedSortOption,
    open,
    openEdit,
    openCheckModal,
    setCheckOpenModal,
    selectedDate,
    selectedDateFrom,
    selectedDateId,
    selectedDateTo,
    selectedDescription,
    updateReports,
    access.reports_do
  ]);

  // Calculate grand total time
  const grandTotalTime = useMemo(() => {
    return rows.reduce((total, row) => {
      const [hours, minutes] = row.allTime.split(':').map(parseFloat);
      return total + hours + minutes / 60;
    }, 0);
  }, [rows]);

  return <div>
    {!rows ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Box>
    ) : (
      <>
        {renderUserReports()}
        {rows.length ?
          (<div className="text-right p-[10px] font-bold bg-[var(--bg-total)]">
            {t('TotalAll')}: {formatTotalTime(grandTotalTime)}
          </div>) : ('')
        }
      </>
    )}
  </div>;
};

export default ApproveTable;
