import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { useLocation } from "react-router-dom";

interface SelectProps {
  selectedTasks: any,
  setSelectedTasks: any
}


const SelectTaskReport: React.FC<SelectProps> = ({ selectedTasks, setSelectedTasks }) => {
  const [tasks, setTasks] = useState<any[]>([]);
  const location = useLocation();

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedTasks(selectedOptions);
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: taskData } = await axios.get('task');
        const formattedTasks = taskData.map((task: any) => ({
          value: task.id,
          label: task.name,
        }));
        setTasks(formattedTasks);
      } catch (e) {

      }
    })();
  }, []);

  return (
    <Select
      defaultValue={tasks[0]}
      isMulti
      name="colors"
      placeholder={location.pathname === '/reports' ? 'All tasks' : 'Select tasks...'}
      value={selectedTasks}
      onChange={handleSelectChange}
      options={tasks}
      className="basic-multi-select w-full bg-[var(--bg-input)]"
      classNamePrefix="select"
    />
  )
}

export default SelectTaskReport
