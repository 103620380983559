import React, { useEffect, useState, useCallback } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import { transformToHierarchy } from './util/tree';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DateRangePicker from './reportsComponents/DateRangePicker';
import moment from 'moment';
import ApproveTable from './reportsComponents/ApproveTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import TreeViewComponent from './util/TreeViewComponent';
import SelectTaskReport from './reportsComponents/SelectTaskReport';
import Breadcrums from './util/Breadcrums';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import AproveDownload from './modal/AproveDownload';

interface ReportsProps {
  access: any
}

const Reports: React.FC<ReportsProps> = ({ access }) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const [selectedSortOption, setSelectedSortOption] = useState<any>('user');
  const [allCategory, setAllCategory] = useState<any[]>([]);
  const [allUsers, setAllUsers] = useState({});
  const [reports, setReports] = useState([]);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [selectedUserId, setSelectedUserId] = useState('');
  const [userId, setUserId] = useState('');
  const transformToHierarchyCallback = useCallback(transformToHierarchy, []);
  const [categoryName, setCategoryName] = useState<any[]>([]);
  const [category, setCategory] = useState<any[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number[]>([]);
  const [isCategoryListVisible, setCategoryListVisible] = useState(false);
  const [userInfo, setUserInfo] = useState('');
  const [openCheckModal, setCheckOpenModal] = useState(false);
  const [prevSelectedCategoryId, setPrevSelectedCategoryId] = useState<number[]>([]);
  const [selectedTasks, setSelectedTasks] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [locationName, setLocationName] = useState('');
  const [status, setStatus] = useState('fulfilled');
  const [showAprove, setShowAprove] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedUserId = event.target.value;
    setSelectedUserId(selectedUserId);
  };

  const getAllChildCategories = (categories: any, parentId: any) => {
    let childIds: number[] = [];
    const parentCategory = categories.find((category: any) => category.id === parentId);
    if (parentCategory) {
      childIds = categories
        .filter((category: any) => category.parentId === parentId)
        .flatMap((childCategory: any) => {
          const nestedChildren = getAllChildCategories(categories, childCategory.id);
          return [childCategory.id, ...nestedChildren];
        });
    }
    return [parentId, ...childIds].reduce<number[]>((acc, id) => {
      if (!acc.includes(id)) {
        acc.push(id);
      }
      return acc;
    }, []);
  };

  const rows = selectedUserId === 'all users' ? reports
    ?.filter((item: any) => {
      if (selectedCategoryId.length === 0 || selectedCategoryId[0] === -1) {
        return true
      } else {
        return getAllChildCategories(allCategory, Number(selectedCategoryId[0])).includes(item.categoryId);
      }
    })
    .filter((item: any) => {
      return selectedTasks.every(task => item.tasks.some((t: any) => t.id === task.value));
    })
    .map((item: any) => {
      const startTime = moment(item.startTime);
      const endTime = moment(item.endTime);
      let duration = moment.duration(endTime.diff(startTime));
      if (duration.asMinutes() < 0) {
        duration = moment.duration(endTime.add(1, 'day').diff(startTime));
      }
      const diffInMinutes = endTime.diff(startTime, 'minutes');
      const roundedMinutes = Math.ceil(diffInMinutes / 5) * 5;
      return {
        userId: item.userId,
        date: item.date,
        startTime: item.startTime,
        endTime: item.endTime,
        categoryid: item.categoryId,
        description: item.description,
        dateId: item.id,
        tasks: item.tasks,
        user: item.user,
        categoryTree: `${item.categoryTree}`,
        userName: `${item.user?.first_name} ${item.user?.last_name}`,
        superVisor: `${item.supervisor ? (`${item.supervisor?.first_name} ${item.supervisor?.last_name}`) : ''}`,
        allTime: `${Math.floor(roundedMinutes / 60)}:${(roundedMinutes % 60).toFixed(0).padStart(2, '0')}`,
        status: item.status
      };
    }) : reports
      ?.filter((item: any) => item.userId === Number(selectedUserId))
      .filter((item: any) => {
        if (selectedCategoryId.length === 0 || selectedCategoryId[0] === -1) {
          return true
        } else {
          return getAllChildCategories(allCategory, Number(selectedCategoryId[0])).includes(item.categoryId);
        }
      })
      .filter((item: any) => {
        return selectedTasks.every(task => item.tasks.some((t: any) => t.id === task.value));
      })
      .map((item: any) => {
        const startTime = moment(item.startTime);
        const endTime = moment(item.endTime);
        let duration = moment.duration(endTime.diff(startTime));
        if (duration.asMinutes() < 0) {
          duration = moment.duration(endTime.add(1, 'day').diff(startTime));
        }
        const diffInMinutes = endTime.diff(startTime, 'minutes');
        const roundedMinutes = Math.ceil(diffInMinutes / 5) * 5;
        return {
          userId: item.userId,
          date: item.date,
          startTime: item.startTime,
          endTime: item.endTime,
          categoryid: item.categoryId,
          description: item.description,
          dateId: item.id,
          tasks: item.tasks,
          user: item.user,
          categoryTree: `${item.categoryTree}`,
          userName: `${item.user.first_name} ${item.user.last_name}`,
          superVisor: `${item.supervisor ? (`${item.supervisor?.first_name} ${item.supervisor?.last_name}`) : ''}`,
          allTime: `${Math.floor(roundedMinutes / 60)}:${(roundedMinutes % 60).toFixed(0).padStart(2, '0')}`,
          status: item.status
        };
      });

  const handleClickCategoryBox = (event: React.MouseEvent) => {
    setCategoryListVisible(!isCategoryListVisible);
    if (isCategoryListVisible) {
      setSelectedCategoryId(prevSelectedCategoryId)
    }
    setPrevSelectedCategoryId(selectedCategoryId);
    event.stopPropagation();
  };

  const handleCategoryListClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleClickAddCategoryId = (selectedId: number) => {
    const selectedCategory = allCategory?.find(node => node.id === selectedId);
    if (selectedCategory) {
      setCategoryName(selectedCategory.name)
      setCategoryListVisible(false);
    } else {
      setCategoryName(['All category'])
      console.log('Category not found');
      setCategoryListVisible(false);
    }
  };

  const handleNodeSelect = (nodeId: number) => {
    setSelectedCategoryId([nodeId])
  };

  const updateReports = async () => {
    setLoading(true);
    try {
      const reportRequestData: any = {
        startTime: moment(startDate).startOf('day').format('YYYY-MM-DD'),
        endTime: moment(endDate).endOf('day').format('YYYY-MM-DD')
      };
      if (status !== 'none') {
        reportRequestData.status = [status];
      }
      const { data: allReports } = await axios.post('time-reports/reports', reportRequestData);
      if (allReports) {
        setReports(allReports);
      }
    } catch (error) {
      console.error("Error updating reports:", error);
    } finally {
      setLoading(true);
    }
  };

  const handleXls = async () => {
    try {
      let requestData: any = {
        startTime: moment(startDate).startOf('day').format('YYYY-MM-DD'),
        endTime: moment(endDate).endOf('day').format('YYYY-MM-DD'),
      };
      if (status !== 'none') {
        requestData.status = [status];
      }
      if (selectedUserId && selectedUserId !== 'all users') {
        requestData.userId = Number(selectedUserId);
      }
      if (selectedCategoryId.length > 0 && selectedCategoryId[0] !== -1) {
        requestData.categoryId = Number(selectedCategoryId[0]);
      }
      if (selectedTasks.length > 0) {
        requestData.tasks = selectedTasks.map(task => task.value);
      }
      const response = await axios.post('time-reports/xls', requestData, {
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, 'time-reports.xlsx');
      enqueueSnackbar('File successfully saved', { variant: 'success' });
      setShowAprove(false);
    } catch (error) {
      enqueueSnackbar(`Error updating reports`, { variant: 'error' });
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await axios.get('user');
        const { data: allUsers } = await axios.get('users/supervisor');
        const { data: categoryData } = await axios.get('category');
        const reportRequestData: any = {
          startTime: moment(startDate).startOf('day').format('YYYY-MM-DD'),
          endTime: moment(endDate).endOf('day').format('YYYY-MM-DD')
        };
        if (status !== 'none') {
          reportRequestData.status = [status];
        }
        const { data: allReports } = await axios.post('time-reports/reports', reportRequestData);
        if (allReports) {
          setReports(allReports);
        }
        if (data) {
          setUserId(data.id);
          dispatch(setAuth(true));
          setAllCategory(categoryData)
          const hierarchyData = transformToHierarchyCallback(categoryData);
          setCategory(hierarchyData);
          setLocationName(location.pathname);
          setAllUsers(allUsers);
          setUserInfo(`${data.first_name} ${data.last_name}`);
        }
      } catch (e) {
        dispatch(setAuth(false));
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch, startDate, endDate, transformToHierarchyCallback, location.pathname, status]);

  useEffect(() => {
    const handleDocumentClick = () => {
      if (prevSelectedCategoryId[0] !== selectedCategoryId[0]) {
        setSelectedCategoryId(prevSelectedCategoryId);
      } else {
        setSelectedCategoryId(selectedCategoryId)
      }
      setCategoryListVisible(false);
    };
    if (isCategoryListVisible) {
      document.addEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isCategoryListVisible, prevSelectedCategoryId, selectedCategoryId]);

  useEffect(() => {
    setSelectedUserId('all users');
  }, []);

  return (
    <div>
      <Breadcrums locationName={locationName} />
      {auth && access.reports_get > 0 ? (
        <>
          <div className="bg-[var(--bg-form)] w-full box-users gap-[5px] p-[15px] mb-[15px] rounded-[5px]">
            <div className='flex justify-between items-center'>
              <div>
                <h3 className='text-[20px] mb-[10px]'>{t('Reports')}</h3>
              </div>
              <div className='flex items-center gap-2 justify-end'>
                <p className='text-[16px] font-semibold'>{t('SortBy')}:</p>
                <select
                  value={selectedSortOption}
                  className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
                  onChange={(e) => { setSelectedSortOption(e.target.value) }}>
                  <option value="user">user</option>
                  <option value="category">category</option>
                </select>
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='media-rep flex gap-5 justify-normal items-center flex-wrap'>
                <div>
                  <p className='text-[12px]'>{t('User')}:</p>
                  <select
                    value={selectedUserId}
                    onChange={handleChange}
                    className='input-timesheet text-[var(--text-color)] bg-[var(--bg-input)] w-[200px] p-[4px] rounded-[5px]'>
                    {selectedUserId === 'none' ? (
                      <option value="none" disabled>Select user...</option>
                    ) : ('')}
                    {access.description === -1 ? '' : <option value={userId}>{userInfo}</option>}
                    {Object.values(allUsers).map((user: any) => (
                      <option key={user.id} value={user.id}>{`${user.first_name} ${user.last_name}`}</option>
                    ))}
                    <option value="all users">All users</option>
                  </select>
                </div>
                <div>
                  <p className='text-[12px]'>{t('Date')}:</p>
                  <div className='flex justify-normal items-center'>
                    <DateRangePicker
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate} />
                  </div>
                </div>
                <div>
                  <p className='text-[12px]'>{t('Category')}:</p>
                  <div className="timesheets-category-box w-[250px] bg-[var(--bg-input)] text-[var(--text-color)] relative text-[18px] rounded-[5px] py-[5px] px-[10px] flex justify-between items-center cursor-text" onClick={handleClickCategoryBox}>
                    <div className="timesheets-category-context width-fix">{categoryName.length ? categoryName : 'All category'}</div>
                    <div className="timesheets-category-arrow"><FontAwesomeIcon icon={faChevronDown} className='text-[13px] cursor-pointer opacity-50' /></div>
                    {isCategoryListVisible && (
                      <div className="timesheets-category-list pb-0 w-full absolute top-[35px] right-0 z-[999] py-[5px]" onClick={handleCategoryListClick}>
                        <div className='fix-height mb-[10px]'>
                          <TreeViewComponent
                            nodes={category}
                            onNodeSelect={handleNodeSelect}
                            updateTree={''}
                            openCheckModal={openCheckModal}
                            selectedCategoryId={selectedCategoryId} />
                        </div>
                        <div className="py-[5px] px-[20px] flex justify-end bg-fix"><button className='bg-[var(--btn-user-btn)] text-[var(--text-color)] border-none text-[18px] py-[5px] px-[15px] cursor-pointer rounded-[5px] hover:bg-[var(--btn-user-hover)]'
                          onClick={() => handleClickAddCategoryId(Number(selectedCategoryId[0]))}>select</button></div>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <p className='text-[12px]'>{t('Task')}:</p>
                  <div
                    className='min-w-[250px] text-[18px] fix-ph'>
                    <SelectTaskReport
                      setSelectedTasks={setSelectedTasks}
                      selectedTasks={selectedTasks} />
                  </div>
                </div>
                <div>
                  <p className='text-[12px]'>{t('StatusReport')}:</p>
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className='input-timesheet text-[var(--text-color)] bg-[var(--bg-input)] w-[200px] px-[5px] py-[6px] rounded-[5px]'>
                    <option value="none">All</option>
                    <option value="pending">Pending</option>
                    <option value="fulfilled">Fulfilled</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
              </div>
              <div className='flex justify-end items-end'>
                <Button
                  variant="outlined"
                  sx={{
                    fontSize: 16,
                    textTransform: 'none'
                  }}
                  onClick={() => setShowAprove(true)}
                  disabled={reports.length === 0}>{t('Export')} XLS</Button>
              </div>
            </div>
          </div>
          <div className="bg-[var(--bg-form)] w-full box-users gap-[5px] px-[15px] py-[10px] rounded-[5px]">
            {rows.length > 0 || !loading ? (
              <div className='mt-[20px]'>
                <ApproveTable
                  rows={rows}
                  access={access}
                  updateReports={updateReports}
                  openCheckModal={openCheckModal}
                  setCheckOpenModal={setCheckOpenModal}
                  selectedSortOption={selectedSortOption} />
              </div>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress />
              </Box>
            )}
            {showAprove &&
              <AproveDownload
                showAprove={showAprove}
                setShowAprove={setShowAprove}
                handXls={handleXls}
                textField={'AreYouSureDownload'} />}
          </div>
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      )}

    </div>
  )
}

export default Reports