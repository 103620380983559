import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  px: 4,
  pt: 3,
  pb: 4
};

interface ModalProps {
  openAddClient: boolean,
  setOpenAddClient: any,
  setClientName: any,
  setClientLastName: any,
  setClientCompanyName: any,
  setClientPost: any,
  setClientCity: any,
  setClientStreet: any,
  clientName: string,
  clientLastName: string,
  clientCompanyName: string,
  clientPost: string,
  clientCity: string,
  clientStreet: string,
  clientEmail: string,
  setClientEmail: any,
  handleAddClients: any,
  setClientPhone: any,
  clientPhone: string
}


const AddClient: React.FC<ModalProps> = ({
  openAddClient,
  setOpenAddClient,
  setClientName,
  setClientLastName,
  setClientCompanyName,
  setClientPost,
  setClientCity,
  setClientStreet,
  clientName,
  clientLastName,
  clientCompanyName,
  clientPost,
  clientCity,
  clientStreet,
  clientEmail,
  setClientEmail,
  setClientPhone,
  clientPhone,
  handleAddClients }) => {
  const handleClose = () => setOpenAddClient(false);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openAddClient}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openAddClient}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2"
              className='text-center'>
              <div className='mb-1'>
                Add Client
              </div>
              <div>
                <div className='mb-2'>
                  <input
                    className='w-full text-[18px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                    type="text"
                    placeholder='name'
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)} />
                </div>
                <div className='mb-2'>
                  <input
                    className='w-full text-[18px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                    type="text"
                    placeholder='lastname'
                    value={clientLastName}
                    onChange={(e) => setClientLastName(e.target.value)} />
                </div>
                <div className='mb-2'>
                  <input
                    className='w-full text-[18px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                    type="text"
                    placeholder='email'
                    value={clientEmail}
                    onChange={(e) => setClientEmail(e.target.value)} />
                </div>
                <div className='mb-2'>
                  <input
                    className='w-full text-[18px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                    type="text"
                    placeholder='phone'
                    value={clientPhone}
                    onChange={(e) => setClientPhone(e.target.value)} />
                </div>
                <div className='mb-2'>
                  <input
                    className='w-full text-[18px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                    type="text"
                    placeholder='company name'
                    value={clientCompanyName}
                    onChange={(e) => setClientCompanyName(e.target.value)} />
                </div>
                <div>
                  <p className='mb-1 mt-3'>Address info</p>
                  <div className='flex gap-1 mb-2'>
                    <div className='w-[45%]'>
                      <input
                        className='w-full text-[18px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                        type="text"
                        placeholder='post'
                        value={clientPost}
                        onChange={(e) => setClientPost(e.target.value)} />
                    </div>
                    <div>
                      <input
                        className='w-full text-[18px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                        type="text"
                        placeholder='city'
                        value={clientCity}
                        onChange={(e) => setClientCity(e.target.value)} />
                    </div>
                  </div>
                  <div>
                    <div className='mb-2'>
                      <input
                        className='w-full text-[18px] py-[5px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                        type="text"
                        placeholder='street'
                        value={clientStreet}
                        onChange={(e) => setClientStreet(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 3 }}
              className='flex justify-between items-center gap-4'>
              <button
                className='w-full text-[16px] uppercase py-[8px] px-[15px] rounded-[5px] text-white bg-[#1976d2] cursor-pointer border border-black border-opacity-20 hover:bg-[#176bbe]'
              onClick={() => handleAddClients()}>
                Add</button>
              <button
                className='w-full text-[16px] uppercase py-[8px] px-[15px] rounded-[5px] text-white bg-[#1976d2] cursor-pointer border border-black border-opacity-20 hover:bg-[#176bbe]'
                onClick={() => handleClose()}>
                Cancel</button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default AddClient;